<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="fieldBatchesSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <v-flex v-if="user.parentType !== 'Company::Depository'" xs12 class="pb-4">
        <v-card>
          <v-card-text class="">
            <v-layout row wrap>
              <template v-if="user.parentType === 'Company::Central' || user.parentType === 'Company::Promotor'">
                <v-flex xs4 class="pa-2">
                  <v-select
                    v-model="companyLocalSelected"
                    :items="companyLocals"
                    outlined
                    item-text="name"
                    item-value="id"
                    :label="$t('fieldBatches.companyLocal')"
                    hide-details
                  />
                </v-flex>
                <v-flex xs4 class="pa-2">
                  <v-select
                    v-model="companyDepositorySelected"
                    :items="companyDepositoriesFiltered"
                    outlined
                    item-text="name"
                    item-value="id"
                    :label="$t('fieldBatches.companyDepository')"
                    hide-details
                  />
                </v-flex>
              </template>
              <template v-else-if="user.parentType === 'Company::Local'">
                <v-flex xs8 class="pa-2">
                  <v-select
                    v-model="companyDepositorySelected"
                    :items="companyDepositoriesFiltered"
                    outlined
                    item-text="name"
                    item-value="id"
                    :label="$t('fieldBatches.companyDepository')"
                    hide-details
                  />
                </v-flex>
              </template>

              <v-flex xs4 class="pa-2 d-flex align-center justify-end">
                <v-btn v-if="isFiltered" class="mr-1" icon small color="secondary"
                       @click="reloadData()"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn color="primary" @click="filterData()">{{ $t('actions.filter') }}</v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex>
        <v-card
          id="fieldBatchesCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>

            <v-spacer />

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  icon
                  ma-0
                  style="margin: 0;"
                  v-on="on"
                  @click="newItem"
                >
                  <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                </v-btn>  
              </template> 

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="py-2">
            <v-text-field
              id="fieldBatchesSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-data-table
              :headers="headers"
              :items="dataList"
              :search="search"
              :options.sync="pagination"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tr v-for="item in items" :key="item.id">
                  <td>{{ item.reference }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.location }}</td>
                  <td>{{ $t(`${objectName.multiple}.fieldBatchTypes.${item.fieldBatchType}`) }}</td>
                  <td>{{ $t(`${objectName.multiple}.hasSecurityOptions.${item.hasSecurity}`) }}</td>
                  <td>{{ item.loraNode }}</td>
                  <td>{{ item.localMonitor }}</td>
                  <td style="text-align: right; padding: 0;">
                    <v-btn
                      text
                      icon
                      rounded
                      color="info"
                      @click="showItem(item.id)"
                    >
                      <v-icon>mdi-file-find-outline</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      icon
                      rounded
                      color="warning"
                      @click="editItem(item.id)"
                    >
                      <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>

                    <v-btn
                      text
                      icon
                      rounded
                      color="error"
                      @click="deleteItem(item.id)"
                    >
                      <v-icon>mdi-file-cancel-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-tabs 
            background-color="primary"
            class="text--white"
            slider-color="primary"
            grow
            dark
          >
            <v-tab>{{ $t(`${objectName.multiple}.tabs.basic`) }}</v-tab>
            <v-tab>{{ $t(`${objectName.multiple}.tabs.documentation`) }}</v-tab>
            <v-tab>{{ $t(`${objectName.multiple}.tabs.expiredInformation`) }}</v-tab>
            <v-tab-item key="1" class="px-5">
              <v-layout row wrap pa-1 align-center justify-center>
                <v-flex v-if="user.parentType != 'Company::Depository'" class="pl-2 pr-2" xs12 sm6>
                  <v-select
                    v-model="object.companyDepositoryId"
                    :items="companyDepositories"
                    :label="$t(`${objectName.multiple}.companyDepositoryId`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                    :error="objectErrors.companyDepository !== undefined"
                    :error-messages="parseErrors(objectErrors.companyDepository)"
                    @change="changeLocalMonitor"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12 :class="user.parentType != 'Company::Depository' ? 'sm6' : ''">
                  <v-text-field
                    v-model="object.name"
                    :label="$t(`${objectName.multiple}.name`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.name !== undefined"
                    :error-messages="parseErrors(objectErrors.name)"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12>
                  <v-text-field
                    v-model="object.address"
                    :label="$t(`${objectName.multiple}.address`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.address !== undefined"
                    :error-messages="parseErrors(objectErrors.address)"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2 pb-2" xs12 sm6>
                  <v-select
                    v-model="object.province"
                    :items="provinces"
                    :label="$t(`${objectName.multiple}.province`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="iso_nombre"
                    item-value="iso_nombre"
                    :error="objectErrors.province !== undefined"
                    :error-messages="parseErrors(objectErrors.province)"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2 pb-2" xs12 sm6>
                  <v-select
                    v-model="object.location"
                    :items="locations"
                    :label="$t(`${objectName.multiple}.location`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="nombre"
                    item-value="nombre"
                    :error="objectErrors.location !== undefined"
                    :error-messages="parseErrors(objectErrors.location)"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-text-field
                    v-model="localMonitor"
                    :label="$t(`${objectName.multiple}.localMonitor`)" 
                    :disabled="true"
                    :color="inputColor"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-select
                    v-model="object.loraNodeId"
                    :items="loraNodes"
                    :label="$t(`${objectName.multiple}.loraNodeId`)"
                    :disabled="!isEditting" 
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                    @change="reDrawNode"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-select
                    v-model="object.fieldBatchType"
                    :items="fieldBatchTypes"
                    :label="$t(`${objectName.multiple}.fieldBatchType`)"
                    :disabled="!isEditting" 
                    :color="inputColor"
                    item-text="text"
                    item-value="value"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-select
                    v-model="object.hasSecurity"
                    :items="hasSecurityOption"
                    :label="$t(`${objectName.multiple}.hasSecurity`)"
                    :disabled="!isEditting" 
                    :color="inputColor"
                    item-text="text"
                    item-value="value"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2 pb-2" xs12 sm6>
                  <v-switch 
                    v-model="object.sustainable"
                    :disabled="!isEditting" 
                    :label="$t(`${objectName.multiple}.sustainable`)"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2 pb-2" xs12 sm6>
                  <v-select
                    v-if="object.sustainable"
                    v-model="object.sustainableState"
                    :items="sustainableStates"
                    :label="$t(`${objectName.multiple}.sustainableState`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="name"
                    item-value="value"
                    :error="objectErrors.sustainableState !== undefined"
                    :error-messages="parseErrors(objectErrors.sustainableState)"
                  />
                </v-flex>
                <v-flex xs12>
                  <div id="fieldMap" style="width: 100%; height: 250px;" />
                </v-flex>           
              </v-layout>
            </v-tab-item>
            <v-tab-item key="2" class="px-5">
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12>
                  <v-data-table
                    :headers="documentationHeaders"
                    :items="object.documentation"
                    :options.sync="pagination"
                    :footer-props="{
                      'next-icon': 'mdi-chevron-right',
                      'prev-icon': 'mdi-chevron-left'
                    }"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>
                          <v-select
                            v-model="item.status"
                            :items="documentationStatuses"
                            :label="$t(`${objectName.multiple}.document.status`)"
                            :disabled="!isEditting" 
                            :color="inputColor"
                            item-text="text"
                            item-value="value"
                            :error="item.status === 'approved' && !(object.loraNodeId && object.fieldBatchType)"
                            :error-messages="getDocumentationError(item)"
                          />
                        </td>
                        <td>
                          <v-text-field
                            v-model="item.comment"
                            :label="$t(`${objectName.multiple}.document.comment`)" 
                            :disabled="!isEditting" 
                            :color="inputColor"
                          />
                        </td>
                        <td>{{ item.type }}</td>
                        <td>{{ `${(item.size / 1000).toFixed(2)} kb` }}</td>
                        <td style="text-align: right; padding: 0;">
                          <v-btn
                            text
                            icon
                            rounded
                            color="info"
                            @click="openDocument(item.url)"
                          >
                            <v-icon>mdi-file-find-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item key="3" class="px-5">
              <v-layout row wrap pa-1 align-center justify-center>
                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="object.expirationDate"
                    persistent
                    lazy
                    full-width
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="object.expirationDate"
                        :label="$t(`${objectName.multiple}.expirationDate`)" 
                        :disabled="!isEditting"
                        :color="inputColor"
                        :error="objectErrors.expirationDate !== undefined"
                        :error-messages="parseErrors(objectErrors.expirationDate)"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="object.expirationDate" scrollable>
                      <v-spacer />
                      <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                      <v-btn flat color="primary" @click="$refs.dialog.save(object.expirationDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-text-field
                    v-model="object.previousDay"
                    :label="$t(`${objectName.multiple}.previousDay`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.previousDay !== undefined"
                    :error-messages="parseErrors(objectErrors.previousDay)"
                    type="number"
                    min="0"
                    @change="validatePreviousDay"
                  />
                </v-flex>
                <v-flex class="pl-2 pr-2" xs12>
                  <v-autocomplete
                    v-model="object.users"
                    :items="usersSelect"
                    :label="$t(`helpers.destinations`)"
                    :disabled="!isEditting"
                    :dark="dark"
                    multiple
                    item-value="id"
                    small-chips
                  >
                    <template slot="selection" slot-scope="{ item }">
                      <v-chip>
                        <span>  {{ item.username }} {{ $t(`users.${item.parentType.split('::')[1]}`) }}  </span>
                      </v-chip>
                    </template>

                    <template slot="item" slot-scope="{ item }">
                      <span>  {{ item.username }} {{ $t(`users.${item.parentType.split('::')[1]}`) }}  </span>
                    </template>
                  </v-autocomplete> 
                </v-flex>          
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined" :disabled="!validateSave" :state="buttonState" color="primary" @click="saveChanges">{{ $t('actions.save') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :disabled="!validateSave" :state="buttonState" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
          
          <v-btn color="primary" @click="editItem(object.id)">{{ $t('actions.edit', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDelete', { name: $t(`${objectName.multiple}.objectName`) }) }}</v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/fieldBatches/add.gql'
import edit from '@/graphql/mutations/fieldBatches/edit.gql'
import object from '@/graphql/queries/fieldBatches/detail.gql'
import moment from 'moment'

// Json of the provinces and locations
import provinces from '@/json/provinces.json'
import locations from '@/json/locations.json'

const objectName = {
  single: 'fieldBatch',
  multiple: 'fieldBatches',
  object: {
    single: 'FieldBatch',
    multiple: 'FieldBatches'
  }
}

export default {
  components: {
    AButton
  },

  data () {
    return {
      objectName,
      localMonitor: '',
      pagination: { rowsPerPage: 10 },
      formModal: false,
      modal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      map: null,
      drawingManager: null,
      geofence: null,
      bagGeofences: [],
      nodeGeofence: null,
      newShape: null,
      deleteId: 0,
      object: {},
      objectErrors: {},
      search: '',
      headers: [
        {
          text: this.$i18n.t(`${objectName.multiple}.reference`),
          align: 'left',
          sortable: true,
          value: 'reference'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.name`),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.location`),
          align: 'left',
          sortable: true,
          value: 'location'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.fieldBatchType`),
          align: 'left',
          sortable: true,
          value: 'fieldBatchType'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.hasSecurity`),
          align: 'left',
          sortable: true,
          value: 'hasSecurity'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.loraNode`),
          align: 'left',
          sortable: true,
          value: 'loraNode'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.localMonitor`),
          align: 'left',
          sortable: true,
          value: 'localMonitor'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ],
      defaultDocumentation: [
        {
          label: this.$i18n.t(`${objectName.multiple}.documentation.document1`),
          value: null
        },
        {
          label: this.$i18n.t(`${objectName.multiple}.documentation.document2`),
          value: null
        },
        {
          label: this.$i18n.t(`${objectName.multiple}.documentation.document3`),
          value: null
        }
      ],
      companyLocalSelected: null,
      companyDepositorySelected: null,
      dataList: [],
      isFiltered: false,
      keyFocus: false,
      documentationHeaders: [
        {
          text: this.$i18n.t(`${objectName.multiple}.document.name`),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.document.status`),
          align: 'left',
          sortable: true,
          value: 'status'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.document.comment`),
          align: 'left',
          sortable: true,
          value: 'comment'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.document.actions`),
          align: 'right',
          sortable: false
        }
      ]
    }
  },

  computed: {
    ...mapState(['user', 'width', 'dark', 'language', 'loraNodes', 'companyLocals', 'companyDepositories', 'bags', 'usersSelect']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor']),
    documentationStatuses () {
      const options = [
        {
          text: this.$i18n.t(`${objectName.multiple}.document.statuses.pending`),
          value: 'pending'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.document.statuses.semiapproved`),
          value: 'semiapproved'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.document.statuses.rejected`),
          value: 'rejected'
        }
      ]
      // Validate if the object (field batche) have lora node id and if they have a type of field batch
      options.push({
        text: this.$i18n.t(`${objectName.multiple}.document.statuses.approved`),
        value: 'approved'
      })
      return options
    },
    validateSave () {
      // Validate if the object have a document with approve status
      let approved = false
      if (this.object.documentation) {
        approved = this.object.documentation.find(doc => doc.status === 'approved')
      }
      if (approved) {
        return this.object.loraNodeId && this.object.fieldBatchType
      }
      return true
    },
    fieldBatchTypes () {
      return [
        {
          text: this.$i18n.t(`${objectName.multiple}.fieldBatchTypes.field`),
          value: 'field'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.fieldBatchTypes.plant`),
          value: 'plant'
        }
      ]
    },
    hasSecurityOption () {
      return [
        {
          text: this.$i18n.t(`${objectName.multiple}.hasSecurityOptions.yes`),
          value: true
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.hasSecurityOptions.no`),
          value: false
        }
      ]
    },
    defaultObject () {
      return {
        id: undefined,
        reference: '',
        name: '',
        address: '',
        geofence: [],
        companyDepositoryId: 0,
        loraNodeId: 0,
        previousDay: 0,
        users: [],
        province: '',
        location: '',
        fieldBatchType: 'field',
        hasSecurity: false
      }
    },

    sustainableStates () {
      return [
        {
          name: this.$i18n.t(`${objectName.multiple}.sustainableStates.pending`),
          value: 'pending'
        },
        {
          name: this.$i18n.t(`${objectName.multiple}.sustainableStates.approved`),
          value: 'approved'
        },
        {
          name: this.$i18n.t(`${objectName.multiple}.sustainableStates.rejected`),
          value: 'rejected'
        }
      ]
    },

    companyDepositoriesFiltered () {
      const result = this.companyDepositories.filter(company =>
        company.parentId === this.companyLocalSelected
      )

      return result
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.name })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.name })
        }
      }
    },

    provinces () {
      return provinces.provincias.sort((a, b) => {
        if (a.iso_nombre < b.iso_nombre) {
          return -1
        }
        if (a.iso_nombre > b.iso_nombre) {
          return 1
        }
        return 0
      })
    },

    locations () {
      return locations.localidades.filter((loc) => loc.provincia.nombre === this.object.province).sort((a, b) => {
        if (a.nombre < b.nombre) {
          return -1
        }
        if (a.nombre > b.nombre) {
          return 1
        }
        return 0
      })
    }
  },

  watch: {
    data: {
      handler (data) {
        this.dataList = this.data
      },
      deep: true
    },
    companyLocalSelected: {
      handler (data) {
        this.companyDepositorySelected = null
      },
      deep: true
    },
    formModal: {
      handler (data) {
        this.localMonitor = ''
        if (this.object.companyDepositoryId) {
          this.changeLocalMonitor(this.object.companyDepositoryId)
        }
      },
      deep: true
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    this.dataList = this.data

    const card = document.querySelector('#fieldBatchesCard')
    const search = document.getElementById('fieldBatchesSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    changeLocalMonitor (value) {
      try {
        this.localMonitor = this.companyLocals.find((company) => company.id === this.companyDepositories.find(company => company.id === value).parentId).name
      } catch (error) {
        this.localMonitor = 'La empresa depositaria no tiene monitor local asignado'
      }
    },
    openDocument (documentUrl) {
      window.open(documentUrl, '_blank')
    },
    cleanDocument (index) {
      this.documentation[index].value = null
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    processDocument (document, index) {
      console.log(document)
      document = document[0]
      this.documentation[index].value = {
        name: document.name,
        size: document.size.toString(),
        type: document.type
      }
      
      this.getBase64(document).then(
        (data) => {
          console.log(data)
          this.documentation[index].value.base64 = data
        } 
      )
    },

    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    validatePreviousDay (value) {
      if (parseInt(value) <= 0) {
        this.object.previousDay = 0
      }
    },
    handleScroll (event) {
      const elem = document.getElementById('fieldBatchesCard')
      const search = document.getElementById('fieldBatchesSearchFixed').childNodes[0]
      const searchCard = document.getElementById('fieldBatchesSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },

    filterData () {
      if (this.companyDepositorySelected !== null) {
        this.dataList = this.data.filter(element => element.companyDepositoryId === this.companyDepositorySelected)
        this.isFiltered = true
      } else if (this.companyLocalSelected !== null) {
        this.dataList = this.data.filter(element => element.companyLocalId === this.companyLocalSelected)
        this.isFiltered = true
      }
    },

    reloadData () {
      this.dataList = this.data
      this.isFiltered = false
    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }
      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`edit${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      
      setTimeout(this.initMapEdit, 1000)
      this.isEditting = true
    },

    initMapEdit () {
      if (this.drawingManager === null) { 
        this.drawingManager = new window.google.maps.drawing.DrawingManager({
          drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['polygon']
          },
          polygonOptions: {
            fillColor: '#823d8f',
            fillOpacity: 0.5,
            strokeColor: '#823d8f',
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            editable: true,
            zIndex: 1
          }
        })
        this.drawingManager.setMap(this.map)
        window.google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.attachGeofence)
      } else {
        this.drawingManager.setMap(this.map)

        this.drawingManager.setOptions({
          drawingControl: true
        })
        this.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
      }
    },

    async showItem (id) {
      this.documentation = this.defaultDocumentation
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single]
          }
          // Proccess the documentation
          for (let i = 0; i < result[objectName.single].documentation.length; i++) {
            this.documentation[i].value = result[objectName.single].documentation[i]
          }
          this.formModal = true
          
          this.isEditting = false
          setTimeout(this.initMapShow, 1000)
          setTimeout(this.drawNode, 1000)
          setTimeout(this.drawBags, 1000)
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    reDrawNode () {
      if (this.nodeGeofence !== null) {
        this.nodeGeofence.setMap(null)
      }
      this.drawNode()
    },

    cleanGeofences () {
      this.nodeGeofence.setMap(null)
      for (var i in this.bagGeofences) {
        this.bagGeofences[i].setMap(null)
      }
      this.bagGeofences = []
    },

    drawNode () {
      const node = this.loraNodes.find((item) => item.id === this.object.loraNodeId)
      this.nodeGeofence = new google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: this.map,
        center: { lat: parseFloat(node.geofence.latitude), lng: parseFloat(node.geofence.longitude) },
        radius: parseFloat(node.geofence.radius)
      })
    },

    drawBags () {
      if (this.object.id !== undefined) {
        this.bagGeofences = []
        const map = this.map
        const bags = this.bags.filter((item) => item.fieldBatchId === this.object.id)
        for (var i in bags) {
          const object = bags[i]
          this.bagGeofences.push(new window.google.maps.Polygon({
            paths: object.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
            strokeColor: '#46FF33',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#46FF33',
            fillOpacity: 0.35
          }))
          this.bagGeofences[i].setMap(map)
        }
      }
    },

    initMapShow () {
      const object = this.object

      var bounds = new window.google.maps.LatLngBounds()
      var i

      for (i = 0; i < this.object.geofence.length; i++) {
        const coordinates = new window.google.maps.LatLng(this.object.geofence[i].latitude, this.object.geofence[i].longitude)
        bounds.extend(coordinates)
      }
      const center = { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() } 

      if (this.map === null) {
        this.map = new window.google.maps.Map(document.getElementById('fieldMap'), {
          zoom: 10,
          center: center
        })
      } else {
        this.map.setCenter(center)
        this.map.setZoom(10)
      }

      if (this.geofence !== null) {
        this.geofence.setMap(null)
      }

      this.geofence = new window.google.maps.Polygon({
        paths: object.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
        strokeColor: '#1565c0',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#1565c0',
        fillOpacity: 0.35
      })

      this.geofence.setMap(this.map)
      const coords = {
        image_one_latitude: -32.9057242, 
        image_two_latitude: -32.9057413, 
        image_one_longitude: -60.8008414, 
        image_two_longitude: -60.800547
      }
      var marker = new window.google.maps.Marker({
        position: {
          lat: coords.image_one_latitude,
          lng: coords.image_one_longitude
        },
        map: this.map,
        title: 'marker 1!'
      })
      var marker = new window.google.maps.Marker({
        position: {
          lat: coords.image_two_latitude,
          lng: coords.image_two_longitude
        },
        map: this.map,
        title: 'marker 2!'
      })
    },

    discardChanges () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      if (this.geofence != null) {
        this.geofence.setMap(null)
      }
      if (this.newShape != null) {
        this.newShape.setMap(null)
      }
      if (this.drawingManager != null) {
        this.drawingManager.setDrawingMode(null)

        this.drawingManager.setOptions({
          drawingControl: false
        })  
      }
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].clear()
      this.formModal = false
      this.isEditting = false
      this.cleanGeofences()
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
      
      setTimeout(this.initMapNew, 1000)
    },

    initMapNew () {
      if (this.map === null) {
        const center = { lat: -32.3671182, lng: -64.4977879 }
        this.map = new window.google.maps.Map(document.getElementById('fieldMap'), {
          zoom: 10,
          center: center
        })
      } else {
        this.map.setCenter({ lat: -32.3671182, lng: -64.4977879 })
        this.map.setZoom(10)
      }
      if (this.drawingManager === null) {
        this.drawingManager = new window.google.maps.drawing.DrawingManager({
          drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['polygon']
          },
          polygonOptions: {
            fillColor: '#823d8f',
            fillOpacity: 0.5,
            strokeColor: '#823d8f',
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            editable: true,
            zIndex: 1
          }
        })

        this.drawingManager.setMap(this.map)
        window.google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.attachGeofence)
      } else {
        this.drawingManager.setOptions({
          drawingControl: true
        })
        this.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
        this.object.geofence = []
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].clear()
      }
    },

    attachGeofence (e) {
      this.newShape = e.overlay
      this.newShape.setMap(null)
      const drawingManager = this.drawingManager
      this.drawingManager.setDrawingMode(null)

      this.drawingManager.setOptions({
        drawingControl: false
      })
      this.object.geofence = []
      const points = e.overlay.getPath().getArray()
      for (const i in points) {
        this.object.geofence.push({
          latitude: points[i].lat(),
          longitude: points[i].lng()
        })
      }
      var bounds = new window.google.maps.LatLngBounds()

      for (let i = 0; i < this.object.geofence.length; i++) {
        const coordinates = new window.google.maps.LatLng(this.object.geofence[i].latitude, this.object.geofence[i].longitude)
        bounds.extend(coordinates)
      }
      const center = { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() } 
      if (this.map === null) {
        this.map = new window.google.maps.Map(document.getElementById('bagMap'), {
          zoom: 10,
          center: center
        })
      } else {
        this.map.setCenter(center)
        this.map.setZoom(10)
      }

      if (this.geofence !== null) {
        this.geofence.setMap(null)
      }

      this.geofence = new window.google.maps.Polygon({
        paths: this.object.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
        strokeColor: '#1565c0',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#1565c0',
        fillOpacity: 0.35
      })

      this.geofence.setMap(this.map)
      this.deleteGeofenceButton(this.drawingManager, this.newShape, this.object)
    },

    deleteGeofenceButton (drawingManager, newShape, object) {
      var centerControlDiv = document.createElement('div')
      // Set CSS for the control border.
      var controlUI = document.createElement('div')
      controlUI.style.backgroundColor = '#fff'
      controlUI.style.border = '2px solid #fff'
      controlUI.style.borderRadius = '3px'
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
      controlUI.style.cursor = 'pointer'
      controlUI.style.marginBottom = '22px'
      controlUI.style.textAlign = 'center'
      controlUI.style.margin = '10px'
      controlUI.title = 'Redibujar geocerca'
      centerControlDiv.appendChild(controlUI)

      // Set CSS for the control interior.
      var controlText = document.createElement('div')
      controlText.style.color = 'rgb(25,25,25)'
      controlText.style.fontFamily = 'Roboto,Arial,sans-serif'
      controlText.style.fontSize = '16px'
      controlText.style.lineHeight = '38px'
      controlText.style.paddingLeft = '5px'
      controlText.style.paddingRight = '5px'
      controlText.innerHTML = 'Redibujar Geocerca'
      controlUI.appendChild(controlText)
      const map = this.map
      // Setup the click event listeners: simply set the map to Chicago.
      controlUI.addEventListener('click', function () {
        newShape.setMap(null)
        drawingManager.setOptions({
          drawingControl: true
        })
        drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
        // object.geofence = []
        map.controls[google.maps.ControlPosition.TOP_RIGHT].clear()
      })
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv)
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
    },

    humanFileSize (bytes, si=false, dp=1) {
      const thresh = si ? 1000 : 1024

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
      }

      const units = si 
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      const r = 10**dp

      do {
        bytes /= thresh
        ++u
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
      return bytes.toFixed(dp) + ' ' + units[u]
    },

    graphQLWrapper (mutationName) {
      this.buttonState = true
      this.objectErrors = {}
      const object = Object.assign({}, this.object)
      object.expirationDate = moment(this.object.expirationDate).unix()
      object.previousDay = parseInt(object.previousDay)
      // object.documentation = this.documentation.map((document) => document.value)
      if (object.documentation) {
        for (let i = 0; i < object.documentation.length; i++) {
          if (object.documentation[i]) {
            delete object.documentation[i].__typename
          }
        }
      }
      const variables = {
        object: object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename

      let mutation = add

      if (mutationName.includes('edit')) {
        mutation = edit
      }
      if (this.object.geofence.length === 0) {
        this.$store.commit('toggleAlert', {
          type: 'warning',
          message: this.$i18n.t('errors.invalidGeofenceLenght')
        })
        this.buttonState = false
        return
      }
      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data[mutationName]
        if (status === 'OK') {
          this.object = {
            ...result
          }

          // Proccess the documentation
          if (this.object.documentation) {
            for (let i = 0; i < result.documentation.length - 1; i++) {
              this.documentation[i].value = result.documentation[i]
            }
          }
          if (this.drawingManager !== undefined) {
            this.drawingManager.setMap(null)
          }

          this.map.controls[google.maps.ControlPosition.TOP_RIGHT].clear()

          if (this.geofence != undefined) {
            this.geofence.setMap(null)
          }
          const object = this.object
          this.geofence = new window.google.maps.Polygon({
            paths: object.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
            strokeColor: '#1565c0',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#1565c0',
            fillOpacity: 0.35
          })
          if (this.geofence != undefined) {
            this.geofence.setMap(this.map)
          }
          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.${(mutationName.includes('edit') ? 'updated' : 'created')}`, { name: result.name })
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id) {
      this.deleteId = id
      this.deleteDialog = true
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false
    },

    getDocumentationError (item) {
      console.log(item)
      if (item.status === 'approved' && !(this.object.loraNodeId && this.object.fieldBatchType)) {
        return this.$i18n.t(`${this.objectName.multiple}.document.errors`)
      } else {
        return ''
      }
    }
  }
}
</script>
