<template>
  <v-app>
    <c-sidebar />    

    <v-content>
      <c-header />

      <div class="px-4">
        <transition name="component-fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </v-content>
  
    <c-footer />
  </v-app>
</template>

<script>
import { Header, Footer, Sidebar } from '@/components/platform'

export default {
  components: {
    'c-sidebar': Sidebar,
    'c-footer': Footer,
    'c-header': Header
  }
}
</script>
