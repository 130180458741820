<template>
  <v-container pt-0 fluid>
    <v-layout row wrap>
      <v-flex v-if="user.parentType !== 'Company::Depository'" xs12 class="pb-4">
        <v-card>
          <v-card-text class="">
            <v-layout row wrap>
              <template>
                <v-flex xs12 md4 class="pa-2">
                  <v-autocomplete
                    v-model="itemSelected"
                    :items="nodeForSelection"
                    outlined
                    :label="$t('actions.searchNodes')"
                    hide-details
                    :filter="itemFilter"
                    @change="itemSelectedHandler"
                  >
                    <template v-slot:item="{item}">
                      {{ item.name }}
                    </template>    
                    <template v-slot:selection="{item}">
                      {{ item.name }}
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 md4 class="pa-2">
                  <v-autocomplete
                    v-model="itemSelected"
                    :items="fieldBatchesForSelection"
                    outlined
                    :label="$t('actions.searchFieldBatches')"
                    hide-details
                    :filter="itemFilter"
                    @change="itemSelectedHandler"
                  >
                    <template v-slot:item="{item}">
                      {{ item.name }}
                    </template>    
                    <template v-slot:selection="{item}">
                      {{ item.name }}
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 md4 class="pa-2">
                  <v-autocomplete
                    v-model="itemSelected"
                    :items="bagsForSelection"
                    outlined
                    :label="$t('actions.searchBags')"
                    hide-details
                    :filter="itemFilter"
                    @change="itemSelectedHandler"
                  >
                    <template v-slot:item="{item}">
                      {{ item.name }}
                    </template>    
                    <template v-slot:selection="{item}">
                      {{ item.name }}
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 class="pa-2">
                  <v-text-field
                    v-model="coordinates"
                    outlined
                    :label="$t('actions.searchCoordinates')"
                    hide-details                    
                  >
                    <template v-slot:append>
                      <v-btn text small @click="setMarker">
                        <v-icon color="secondary">search</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                
                </v-flex>
              </template>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex>
        <v-card
          class="elevation-12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>
          </v-toolbar>
          
          <v-flex id="map" xs12 style="height: 500px" />
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import createHTMLMapMarker from '@/classess/customHtmlMarkers'

const objectName = {
  single: 'message',
  multiple: 'messages',
  object: {
    single: 'Message',
    multiple: 'Messages'
  }
}
export default {
  data () {
    return {
      objectName,
      search: '',
      itemSelected: '',
      map: undefined,
      geofences: {},
      coordinates: '',
      marker: undefined
    }
  },
  mounted () {
    const center = { lat: -32.3671182, lng: -64.4977879 }
    setTimeout(() => {
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        zoom: 8,
        center: center
      })
    }, 50)

    setTimeout(() => {
      const map = this.map
      this.loraNodes.forEach((node) => {
        const geofence = new window.google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          map: map,
          center: { lat: parseFloat(node.geofence.latitude), lng: parseFloat(node.geofence.longitude) },
          radius: parseFloat(node.geofence.radius)
        })
        this.geofences[node.id] = geofence
        geofence.setMap(map)
        const center = new window.google.maps.LatLng(parseFloat(node.geofence.latitude), parseFloat(node.geofence.longitude))
        const marker = createHTMLMapMarker({
          latlng: center,
          map: map,
          html: `<div style="background-color: rgba(255, 0, 0, 0.747);
                padding: 5px;
                color: white;
                border-radius: 10px;
                text-align: center;
                width: fit-content;"
                >
                    ${node.name}*
                </div>`
        })

        marker.addListener('click', () => {
          map.fitBounds(center)
        })
      })

      this.fieldBatches.forEach((field) => {
        const geofence = new window.google.maps.Polygon({
          paths: field.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
          strokeColor: '#1565c0',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#1565c0',
          fillOpacity: 0.35
        })
        geofence.setMap(map)
        const center = this.getCenterPolygonBounds(field)
        const marker = createHTMLMapMarker({
          latlng: center,
          map: map,
          html: `<div style="background-color: rgba(21, 101, 192, 0.747);
                padding: 5px;
                color: white;
                border-radius: 10px;
                text-align: center;
                width: fit-content;"
                >
                    ${field.name}**
                </div>`
        })
        const bounds = this.getPolygonBounds(field)
        marker.addListener('click', () => {
          map.fitBounds(bounds)
        })
      })
      
      this.bags.forEach((bag) => {
        const geofence = new window.google.maps.Polygon({
          paths: bag.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
          strokeColor: '#20bd5f',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#20bd5f',
          fillOpacity: 0.35
        })
        geofence.setMap(map)
        const center = this.getCenterPolygonBounds(bag)
        const marker = createHTMLMapMarker({
          latlng: center,
          map: map,
          html: `<div style="background-color: rgba(32, 189, 95, 0.747);
                padding: 5px;
                color: white;
                border-radius: 10px;
                text-align: center;
                width: fit-content;"
                >
                    ${bag.reference}***
                </div>`
        })
        const bounds = this.getPolygonBounds(bag)

        marker.addListener('click', () => {
          map.fitBounds(bounds)
        })
      })
    }, 100)
  },

  computed: {
    ...mapState(['loraNodes', 'fieldBatches', 'bags', 'user', 'width', 'dark', 'language']),
    ...mapGetters(['inputColor']),
    nodeForSelection () {
      const elements = []
      this.loraNodes.forEach((node) => elements.push({
        name: node.name,
        type: 'node',
        geofence: node.geofence,
        id: node.id
      }))
      return elements
    },
    fieldBatchesForSelection () {
      const elements = []
      this.fieldBatches.forEach((field) => elements.push({
        name: field.name,
        type: 'field',
        geofence: field.geofence,
        id: field.id
      }))
      return elements
    },

    bagsForSelection () {
      const elements = []
      this.bags.forEach((bag) => elements.push({
        name: bag.reference,
        type: 'bag',
        geofence: bag.geofence,
        id: bag.id
      }))
      return elements
    },
    headers () {
      return [
        // { 
        //   align: 'left',
        //   sortable: true,
        //   value: 'message_id',
        //   text: this.$i18n.t(`${objectName.multiple}.id`), 
        // },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.bagName`),
          value: 'bag_name' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.bagNomenclature`),
          value: 'nomenclature' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.fieldBatch`),
          value: 'field_batch' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.product`),
          value: 'product'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.cycle`),
          value: 'cycle'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.messageTime`),
          value: 'message_time'
        },
        // { 
        //   align: 'left',
        //   sortable: true,
        //   text: this.$i18n.t(`${objectName.multiple}.subscriberId`),
        //   value: 'subscriber_id' 
        // },
        
        // { 
        //   align: 'left',
        //   sortable: true,
        //   text: this.$i18n.t(`${objectName.multiple}.loraNode`),
        //   value: 'lora_node' 
        // },
        // { 
        //   align: 'left',
        //   sortable: true,
        //   text: this.$i18n.t(`${objectName.multiple}.loraSensors`),
        //   value: 'sensor_lora' 
        // },
        // { 
        //   align: 'left',
        //   sortable: true,
        //   text: this.$i18n.t(`${objectName.multiple}.sensors`),
        //   value: 'sensor_bt' 
        // },
        // { 
        //   align: 'left',
        //   sortable: true,
        //   text: this.$i18n.t(`${objectName.multiple}.sensorNumber`),
        //   value: 'sensor' 
        // },
        
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.sequence`),
          value: 'sequence' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.event`),
          value: 'event' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.temperature`),
          value: 'temps' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.voltage`),
          value: 'vbats'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.nodeVoltage`),
          value: 'vbatsg'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.virtualSensor`),
          value: 'virtual_sensor'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }

      ]
    },
    messagesParsed () {
      const messages = []
      const monitor = Object.entries(this.messagesMonitor)
      for (var i = monitor.length - 1; i >= 0; i--) {
        messages.push(monitor[i][1])
      }
      return messages
    }
  },
  methods: {

    date (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    time (time) {
      return moment(time).format('HH:mm:ss')
    },
    
    updateMessages () {
      this.$store.dispatch('getMessages')
    },

    sortByName (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      // a debe ser igual b
      return 0
    },

    getPolygonBounds (item) {
      const bounds = new window.google.maps.LatLngBounds()
      for (var i = 0; i < item.geofence.length; i++) {
        const coordinates = new window.google.maps.LatLng(item.geofence[i].latitude, item.geofence[i].longitude)
        bounds.extend(coordinates)
      }
      return bounds
    },
    getCenterPolygonBounds (item) {
      const bounds = this.getPolygonBounds(item)
      return bounds.getCenter()
    },

    itemFilter (item, queryText, itemText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    itemSelectedHandler (item) {
      this.itemSelected = ''
      if (item.type === 'node') {
        const bounds = this.geofences[item.id].getBounds()
        this.map.fitBounds(bounds)
      } else {
        const bounds = this.getPolygonBounds(item)
        this.map.fitBounds(bounds)
      }
    },
    parseCoordinates () {
      const coordinates = this.coordinates.split(',')
      if (coordinates.length !== 2) {
        return undefined
      }
      try {
        return new window.google.maps.LatLng(parseFloat(coordinates[0]), parseFloat(coordinates[1]))
      } catch (error) {
        return undefined
      }
    },
    setMarker () {
      const coordinates = this.parseCoordinates()
      if (coordinates === undefined) {
        return
      }
      if (!this.marker) {
        this.marker = new window.google.maps.Marker({
          position: coordinates,
          map: this.map
        })
      } else {
        this.marker.setPosition(coordinates)
      }
      this.map.panTo(coordinates)
    }
  }
}
</script>