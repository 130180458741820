export default {
  inputColor (state) {
    return state.dark ? 'white' : 'primary'
  },
  noFinishedOrders (state) {
    return state.orders.filter((order) => order.state !== 'finished')
  },
  finishedOrders (state) {
    return state.orders.filter((order) => order.state === 'finished')
  },
  freeBtSensors (state) {
    return state.btSensors.filter((item) => item.assignedTo === 'no')
  },
  freeLoraSensors (state) {
    return state.loraSensors.filter((item) => item.assignedTo === 'no')
  },
  categoryAlertsLoraSensor (state) {
    return state.categoryAlerts.filter((item) => item.typeSensor == 'loraSensor')
  },
  categoryAlertsLoraNode (state) {
    return state.categoryAlerts.filter((item) => item.typeSensor == 'loraNode')
  },
  categoryAlertsBluetoothSensor (state) {
    return state.categoryAlerts.filter((item) => item.typeSensor == 'bluetoothSensor')
  },

  freeKits (state) {
    return state.kits.filter((item) => item.assignedType == 'none')
  },

  validFields (state) {
    return state.fieldBatches.filter((item) => item.geofence.length > 0 && item.loraNodeId !== null)
  },

  ownedKitsLabeled (state) {
    let kits = state.kits.filter((item) => item.assignedType != 'none')
    for(let i in kits) {
      let string = kits[i].reference + " ("
      for(let j in kits[i].loraSensors){
        string += kits[i].loraSensors[j].name
        string += ' ('
        for(let k in kits[i].loraSensors[j].children){
          string += kits[i].loraSensors[j].children[k].name

          if(k != kits[i].loraSensors[j].children.length -1){
            string += ','
          }
        }
        string += ')'

        if(j != kits[i].loraSensors.length -1){
          string += ','
        }
      }
      string+= ')'

      kits[i].helper = string
    }
    
    return kits
  },

  freeKitsLabeled (state) {
    let kits = state.kits.filter((item) => item.assignedType == 'none')
    for(let i in kits) {
      let string = kits[i].reference + " ("
      for(let j in kits[i].loraSensors){
        string += kits[i].loraSensors[j].name
        string += ' ('
        for(let k in kits[i].loraSensors[j].children){
          string += kits[i].loraSensors[j].children[k].name

          if(k != kits[i].loraSensors[j].children.length -1){
            string += ','
          }
        }
        string += ')'

        if(j != kits[i].loraSensors.length -1){
          string += ','
        }
      }
      string+= ')'

      kits[i].helper = string
    }
    
    return kits
  },

  loraSensorsFree (state) {
    var array = new Array
    var result = new Array
    for(let i in state.bags){
      array = array.concat(state.bags[i].loraSensors)
    }
    for(let i in state.loraSensors){
      let validator = array.includes(state.loraSensors[i].id)
      if(!validator){
        result.push(state.loraSensors[i])
      }
    }
    return result

  },

  loraNodeSensors (state) {
    let data = state.loraNodes
    data.forEach((node) => {
      node.loraSensorsQuantity = state.loraSensors.filter((item) => { return item.loraNodeId == node.id }).length
      node.message = state.loraNodeMessages.find((message) => message.subscriberId === node.subscriberId )

    })
    return data
  },
  loraSensorsBtSensors (state) {
    let data = Object.assign([], state.loraSensors)
    data.forEach((sensor) => {
      let btSensors = state.btSensors.filter((item) => item.loraSensorId == sensor.id )
      let string = btSensors.map((item) => item.sensorId).join(',')
      sensor.btSensorsName = `${sensor.deviceEui} (${string})`
      
    })
    return data
  },
  inputColor (state) {
    return state.dark ? 'white' : 'primary'
  },

  unbindedSensors (state) {
    return state.btSensors.filter((item, index, arr) => { return item.loraSensorId === null })
  },

  bindedSensors (state) {
    return state.btSensors.filter((item, index, arr) => { return item.loraSensorId !== null })
  },

  unlinkedLoraSensors (state) {
    return state.loraSensors.filter((item, index, arr) => { return !item.linked })
  },

  locations (state) {
    let data = []

    for (let i in state.companyCentrals) {
      data.push({
        locationId: state.companyCentrals[i].id,
        locationType: 'Central',
        locationName: state.companyCentrals[i].name
      })
    }

    for (let i in state.companyPromotors) {
      data.push({
        locationId: state.companyPromotors[i].id,
        locationType: 'Promotor',
        locationName: state.companyPromotors[i].name
      })
    }

    for (let i in state.companyLocals) {
      data.push({
        locationId: state.companyLocals[i].id,
        locationType: 'Local',
        locationName: state.companyLocals[i].name
      })
    }

    return data
  },

  companyUsers (state) {
    return state.usersSelect.filter((user) => user.parentType == 'Company::Central')
  },

  nodeMessages (state) {
    for (var i in state.loraNodes) {
      state.loraNodes[i].message = state.loraNodeMessages.find((message) => message.subscriberId === state.loraNodes[i].subscriberId )
    }
    return state.loraNodes
  },

  nodeAlerts (state) {
    return state.alerts.filter((alert) => alert.notificationType === 'node' && alert.notificationStatus === 'unatended')
  },

  bagAlerts (state) {
    return state.alerts.filter((alert) => alert.notificationType === 'bag' && alert.notificationStatus === 'unatended')
  },
  nodeAlertsAtended (state) {
    return state.alerts.filter((alert) => alert.notificationType === 'node' && alert.notificationStatus !== 'unatended')
  },
  bagAlertsAtended (state) {
    return state.alerts.filter((alert) => alert.notificationType === 'bag' && alert.notificationStatus !== 'unatended')
  },
  closedBags (state) {
    return state.bags.filter((bag) => bag.state === 'closed_bag')
  },
  unclosedBags (state) {
    return state.bags.filter((bag) => bag.state !== 'closed_bag')
  }
}