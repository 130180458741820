<template>
  <v-container fluid class="py-2">
    <v-layout row wrap>
      <v-flex xs12>
        <v-tabs v-model="tab" grow dark>
          <v-tab class="white--text">
            {{ $t("notifications.nodeIncidents") }}
          </v-tab>
          <v-tab class="white--text">
            {{ $t("notifications.bagIncidents") }}
          </v-tab>
        </v-tabs>
      </v-flex>
      <v-flex v-if="tab === 0" xs12 class="py-2">
        <v-card>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t("home.nodeIncidents") }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="py-2">
            <v-row class="py-4 px-2">
              <v-flex xs12 md8>
                <v-text-field
                  v-model="nodeSearchView"
                  append-icon="search"
                  :label="$t('actions.search')"
                  single-line
                  :color="inputColor"
                  hide-details
                  @click:append="nodeSearchHandler"
                />
              </v-flex>
            </v-row>
            <v-data-table
              :headers="nodeHeaders"
              :items="nodeNotificationGroups"
              :search="nodeSearchView"
              :options.sync="pagination"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id" @click="selectNotification(item)">
                    <td>{{ String(item.id).padStart(8, '0') }}</td>
                    <td> {{ $t(`alerts.statuses.${item.notificationStatus}`) }} </td>
                    <td>{{ item.name }}</td>
                    <td>{{ parseDate(item.eventAt) }}</td>
                    <!-- Do for in the fieldBatches -->
                    <td> 
                      <tr v-for="(field, index) in item.fieldBatches" :key="index">
                        <td> {{ field.name }} </td>
                      </tr>
                    </td>
                    <td> 
                      <tr v-for="(field, index) in item.fieldBatches" :key="index">
                        <td> {{ !field.companyDepositoryName ? 'N/A' : field.companyDepositoryName }} </td>
                      </tr>
                    </td>
                    <td> 
                      <tr v-for="(field, index) in item.fieldBatches" :key="index">
                        <td> {{ !field.companyLocalName ? 'N/A' : field.companyLocalName }} </td>
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </template>
              <tr slot="no-results">
                <td :colspan="nodeHeaders.length" v-html="$t('helpers.notFound', { nodeSearchView })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex v-if="tab === 1" xs12 class="py-2">
        <v-card>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t("home.bagIncidents") }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="py-2">
            <v-row class="py-4 px-2">
              <v-flex xs12 md8>
                <v-text-field
                  v-model="bagSearchView"
                  append-icon="search"
                  :label="$t('actions.search')"
                  single-line
                  :color="inputColor"
                  hide-details
                  @click:append="bagSearchHandler"
                />
              </v-flex>
            </v-row>
            <v-data-table
              :headers="bagHeaders"
              :items="bagNotificationGroups"
              :search="bagSearchView"
              :options.sync="pagination"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id" @click="selectNotification(item)">
                    <td> {{ String(item.id).padStart(8, '0') }} </td>
                    <td> {{ $t(`alerts.statuses.${item.notificationStatus}`) }} </td>
                    <td>{{ parseDate(item.eventAt) }}</td>
                    <td>{{ item.companyLocalName }}</td>
                    <td>{{ item.companyDepositoryName }}</td>
                    <td>{{ item.fieldBatchName }}</td>
                    <!-- For for the bags -->
                    <td> 
                      <table> 
                        <tr v-for="(bag, index) in prepareNotificationBag(item.notifications)" :key="index">
                          <td style="padding: 0px"> {{ bag.name }} </td>
                        </tr>
                      </table>
                    </td>
                    <td> 
                      <table> 
                        <tr v-for="(bag, index) in prepareNotificationBag(item.notifications)" :key="index">
                          <td style="padding: 0px"> {{ bag.product }} </td>
                        </tr>
                      </table>
                    </td>
                    <td> 
                      <table> 
                        <tr v-for="(bag, index) in prepareNotificationBag(item.notifications)" :key="index">
                          <td style="padding: 0px"> {{ bag.kit }} </td>
                        </tr>
                      </table>
                    </td>
                    <td> 
                      <table> 
                        <tr v-for="(bag, index) in prepareNotificationBag(item.notifications)" :key="index">
                          <td style="padding: 0px"> {{ bag.sensorIdentifier }} </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </template>
              <tr slot="no-results">
                <td :colspan="bagHeaders.length" v-html="$t('helpers.notFound', { nodeSearchView })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap pa-1 align-center justify-center>
            <v-flex xs12 sm6 align="left">
              <h5>{{ $t("notifications.eventAt") }}</h5>
              <b> {{ parseDate(object.eventAt) }} </b>
            </v-flex>
            <v-flex xs12 sm6 align="left">
              <h5>{{ $t("notifications.name") }}</h5>
              <b> {{ object.name }} </b>
            </v-flex>
            <v-flex xs12 align="left">
              <h5>{{ $t("notifications.notificationStatus") }}</h5>
              <b>
                {{ $t(`notificationsStatus.${object.notificationStatus}`) }}
              </b>
            </v-flex>
            <v-flex xs12>
              <h5>{{ $t("notifications.comments") }}</h5>
              <b v-if="object.comments.length == 0">
                {{ $t(`notifications.NoComments`) }}
              </b>
              <v-timeline v-else :dense="true">
                <v-timeline-item
                  v-for="comment in object.comments"
                  :key="comment.timestamp"
                  color="primary"
                  small
                >
                  <v-card class="elevation-2">
                    <v-card-title class="headline">
                      {{ comment.username }}
                      {{ parseDate(comment.timestamp)}}
                    </v-card-title>
                    <v-card-text>
                      <template v-if="comment.imageUrl">
                        <img
                          style="max-width: 100%; height: 250px;"
                          :src="comment.imageUrl.replace('http://89.40.8.38/', 'https://ftp.silobolsas.goldenmcorp.com/')"
                        >
                        <br>
                      </template>
                      {{ comment.comment }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-flex>
            <v-flex
              v-if="object.notificationStatus !== 'finished'"
              xs12
              class="pa-4"
            >
              <v-textarea
                v-model="comment.comment"
                :label="$t(`notifications.comment`)"
                :color="inputColor"
                :error="objectErrors.comment !== undefined"
                :error-messages="parseErrors(objectErrors.comment)"
              />
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions style="max-height: 70px">
          <v-btn color="error" @click="discardChanges">
            {{ $t("actions.close") }}
          </v-btn>

          <v-spacer />

          <a-button
            v-if="
              object.notificationStatus !== 'finished' &&
                user.parentType === 'Company::Central'
            "
            color="primary"
            :state="buttonState"
            @click="executeMutation('close')"
          >
            {{ $t("actions.closeNotification") }}
          </a-button>

          <v-spacer />

          <a-button
            v-if="object.notificationStatus !== 'finished'"
            color="primary"
            :state="buttonState"
            @click="executeMutation('add')"
          >
            {{ $t("actions.addComment") }} 
          </a-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import { RecycleScroller } from 'vue-virtual-scroller'
import AButton from '@/components/Button.vue'

import addComment from '@/graphql/mutations/notifications/addComment.gql'
import finish from '@/graphql/mutations/notifications/finish.gql'
import object from '@/graphql/queries/notifications/detail.gql'
import generateGroup from '@/graphql/mutations/groupNotifications/add.gql' 

const objectName = {
  single: 'message',
  multiple: 'messages',
  object: {
    single: 'Message',
    multiple: 'Messages'
  }
}
export default {
  components: {
    'a-button': AButton
  },
  data () {
    return {
      pagination: { rowsPerPage: 10 },
      itemsPerPage: 10,
      nodePage: 1,
      nodeFilterRadio: 'showAll',
      searchNode: '',
      bagPage: 1,
      bagFilterRadio: 'showAll',
      searchBag: '',
      tab: 0,
      objectName,
      object: {
        comments: []
      },
      comment: {
        id: '',
        comment: ''
      },
      objectErrors: {},
      formModal: false,
      buttonState: false,
      curTime: new Date(),
      selectedBagAlerts: [],
      selectedNodeAlerts: [],
      bagSearch: '',
      bagSearchView: '',
      nodeSearchView: ''
    }
  },
  computed: {
    ...mapState([
      'messagesMonitor',
      'loraNodeMessages',
      'loraSensorsMessages',
      'btSensorsMessages',
      'messagesReport',
      'user',
      'width',
      'dark',
      'language',
      'alerts',
      'bagsMonitorMessages',
      'dictBags',
      'nodeNotificationGroups',
      'bagNotificationGroups'
    ]),
    ...mapGetters(['inputColor', 'nodeAlerts', 'bagAlerts']),
    nodeHeaders () {
      return [
        { text: this.$i18n.t('alerts.identifier'), value: 'id' },
        { text: this.$i18n.t('alerts.status'), value: 'notificationStatus' },
        { text: this.$i18n.t('alerts.name'), value: 'name' },
        { text: this.$i18n.t('alerts.eventAt'), value: 'description' },
        { text: this.$i18n.t('alerts.fieldBatch'), value: 'fieldBatch' },
        { text: this.$i18n.t('alerts.companyDepository'), value: 'evencompanyDepositoryAt' },
        { text: this.$i18n.t('alerts.companyLocal'), value: 'companyLocal' }
      ]
    },
    bagHeaders () {
      return [
        { text: this.$i18n.t('alerts.identifier'), value: 'id' },
        { text: this.$i18n.t('alerts.status'), value: 'notificationStatus' },
        { text: this.$i18n.t('alerts.eventAt'), value: 'description' },
        { text: this.$i18n.t('alerts.companyLocal'), value: 'companyLocal' },
        { text: this.$i18n.t('alerts.companyDepository'), value: 'companyLocal' },
        { text: this.$i18n.t('alerts.fieldBatch'), value: 'fieldBatch' },
        { text: this.$i18n.t('alerts.bag'), value: 'bagName' },
        { text: this.$i18n.t('alerts.product'), value: 'product' },
        { text: this.$i18n.t('alerts.kit'), value: 'kit' },
        { text: this.$i18n.t('alerts.sensorIdentifier'), value: 'sensorIdentifier' }
      ]
    },
    // General alerts filter method
    filteredAlertGroup () {
      if (this.formType === 'fieldBatch') {
        return this.bagAlerts.filter(item => {
          return this.selectedBagAlerts.includes(item.id)
        })
      } else {
        return this.nodeAlerts.filter(item => {
          return this.selectedNodeAlerts.includes(item.id)
        })
      }
    },
    // Filtered bags methods
    filteredBagsAlertGroup () {
      return this.bagAlerts.filter((alert) => this.selectedBagAlerts.includes(alert.id))
    },
    filteredBagsAlert () {
      const list = this.bagAlerts
      if (this.bagSearch.length > 0) {
        const search = this.bagSearch.toLowerCase()
        return list.filter(
          (notification) => {
            return notification.name.toLowerCase().includes(search) ||
            notification.notificationName.toLowerCase().includes(search) ||
            notification.description.toLowerCase().includes(search) ||
            notification.notificationName.toLowerCase().includes(search)
          }
        )
      }
      return list
    },
    // Filtered node methods
    filteredNodeAlertGroup () {
      return this.nodeAlerts.filter((alert) => this.selectedNodeAlerts.includes(alert.id))
    },
    filteredNodeAlert () {
      const list = this.nodeAlerts
      if (this.searchNode.length > 0) {
        const search = this.searchNode.toLowerCase()
        return list.filter(
          (notification) => {
            return notification.name.toLowerCase().includes(search) ||
            notification.notificationName.toLowerCase().includes(search) ||
            notification.description.toLowerCase().includes(search) ||
            notification.notificationName.toLowerCase().includes(search)
          }
        )
      }
      return list
    },
    isCentral  () {
      return this.user.parentType === 'Company::Central'
    },
    title () {
      return `${this.$i18n.t(`incidents.title`)} (${this.object.name})`
    },
    bagsMonitorMessagesArray () {
      const array = []
      for (var i in this.bagsMonitorMessages) {
        array.push(this.bagsMonitorMessages[i])
      }

      return array
    },
    isFullScreen () {
      return this.width.full < 990
    },

    messagesParsed () {
      const messages = []
      const monitor = Object.entries(this.messagesMonitor)
      for (var i = monitor.length - 1; i >= 0; i--) {
        messages.push(monitor[i][1])
      }
      return messages
    },

    reportMessagesParsed () {
      const messages = []
      const monitor = Object.entries(this.messagesReport)
      for (var i = monitor.length - 1; i >= 0; i--) {
        messages.push(monitor[i][1])
      }
      return messages
    },

    filteredNodes () {
      let items = []
      if (this.nodeFilterRadio === 'showAll') {
        items = this.loraNodeMessages
      } else {
        items = this.loraNodeMessages.filter((item) =>
          this.nodeAlerts.find((alert) => alert.relatedId === item.id)
        )
      }
      if (this.searchNode === '') {
        return items
      } else {
        return items.filter((item) =>
          this.validateSearch(
            `${item.name}${item.subscriberId}`,
            this.searchNode
          )
        )
      }
    },

    filteredBags () {
      let items = []

      if (this.bagFilterRadio === 'showAll') {
        items = this.bagsMonitorMessagesArray
      } else {
        items = this.bagsMonitorMessagesArray.filter((item) =>
          this.nodeAlerts.find((alert) => alert.relatedId === item.id)
        )
      }
      if (this.searchBag === '') {
        return items
      } else {
        return items.filter((item) =>
          this.validateSearch(`${item.bagName}${item.kitName}`, this.searchBag)
        )
      }
    }
  },
  created () {
    // const id = setInterval(this.updateMessages, 60000)
    // this.$store.commit('setIntervalId', id)
    setInterval(() => {
      this.curTime = new Date()
    }, 1000)
  },

  methods: {
    groupEvent (type) {
      if (type === 'bag' && this.selectedBagAlerts.length > 0) {
        this.formModal = true
        this.formType = 'fieldBatch'
      } else if (type === 'node' && this.selectedNodeAlerts.length > 0) {
        this.formModal = true
        this.formType = 'node'
      }
    },
    // Bag Alerts methods
    bagSearchHandler () {
      this.bagSearch = this.bagSearchView
    },
    selectBagAlert (id) {
      const index = this.selectedBagAlerts.indexOf(id)
      if (index !== -1) {
        this.selectedBagAlerts.splice(index, 1)
      } else {
        this.selectedBagAlerts.push(id)
      }
    },
    availableBagAlert (relatedId) {
      // Validate if theres bag alerts, if not return true
      if (this.selectedBagAlerts.length === 0) {
        return true
      }
      // Else get the first bag and get the fieldbatch
      const selectedAlert = this.bagAlerts.find((alert) => alert.id === this.selectedBagAlerts[0])
      const fieldBatchId = this.dictBags[selectedAlert.relatedId].fieldBatchId
      if (fieldBatchId === this.dictBags[relatedId].fieldBatchId) {
        return true
      }
      return false
    },
    // Node Alerts methods
    nodeSearchHandler () {
      this.searchNode = this.searchNodeView
    },
    selectNodeAlert (id) {
      const index = this.selectedNodeAlerts.indexOf(id)
      if (index !== -1) {
        this.selectedNodeAlerts.splice(index, 1)
      } else {
        this.selectedNodeAlerts.push(id)
      }
    },
    availableNodeAlert (relatedId) {
      // Validate if theres node alerts, if not return true
      if (this.selectedNodeAlerts.length === 0) {
        return true
      }
      // Else get the first node and get the fieldbatch
      const selectedAlert = this.nodeAlerts.find((alert) => alert.id === this.selectedNodeAlerts[0])
      if (relatedId === selectedAlert.relatedId) {
        return true
      }
      return false
    },

    appendZero (value) {
      if (value < 10) {
        return `0${value}`
      }
      return value
    },
    parseTime (time) {
      const hours = this.appendZero(Math.floor(time / 3600))
      time = time - hours * 3600
      const minutes = this.appendZero(Math.floor(time / 60))
      const seconds = this.appendZero(Math.floor(time - minutes * 60))
      return `${hours}:${minutes}:${seconds}`
    },

    calculateTime (time) {
      const finalTime = (this.curTime / 1000) - time
      return this.parseTime(finalTime)
    },

    restartPaginator () {
      this.nodePage = 1
      this.bagPage = 1
    },

    validateSearch (string, search) {
      return string.toLowerCase().includes(search.toLowerCase())
    },

    async selectNotification (notification) {
      const id = notification.id
      await this.$apollo
        .query({
          query: object,
          variables: {
            id,
            apiToken: this.user.apiToken,
            language: this.language.toUpperCase()
          },
          fetchPolicy: 'no-cache'
        })
        .then((response) => {
          const result = response.data.notification

          if (result.status) {
            this.object = {
              ...result.notification
            }

            this.comment.id = this.object.id

            this.formModal = true
            this.isEditting = false
            setTimeout(this.initMapShow, 1000)
            setTimeout(this.drawNode, 1000)
            setTimeout(this.drawBags, 1000)
          } else {
            console.log('error')
          }
        })
        .catch((error) => {
          this.$store.commit('toggleAlert', {
            type: 'error',
            message: this.$i18n.t('errors.internalError')
          })
        })
    },

    executeMutation (action) {
      let mutation
      let mutationName

      if (this.comment.comment.length < 4) {
        this.objectErrors.comment = [
          'El comentario no puede tener menos de 4 caracters'
        ]
        this.$forceUpdate()
      } else {
        if (action === 'add') {
          mutationName = 'addCommentNotification'
          mutation = addComment
        } else if (action === 'close') {
          mutationName = 'finishNotification'
          mutation = finish
        }

        const variables = {
          object: this.comment,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        }
        this.buttonState = true
        this.$apollo
          .mutate({
            mutation,
            variables
          })
          .then((response) => {
            const { status, errors, result } = response.data[mutationName]
            if (status === 'OK') {
              this.object = {
                ...result
              }
              this.comment = {
                id: this.object.id,
                comment: ''
              }

              this.objectErrors = {}
              this.$store.dispatch('getMessagesMonitor')
              this.$store.commit('toggleAlert', {
                type: 'success',
                message: this.$i18n.t(
                  `notifications.success.${
                    mutationName.includes('close') ? 'closed' : 'added'
                  }`
                )
              });
            } else {
              this.objectErrors = errors
              this.$store.commit('toggleAlert', {
                type: 'warning',
                message: this.$i18n.t('errors.invalidFields')
              })
            }
          })
          .catch((error) => {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: this.$i18n.t('errors.internalError')
            })
          })
          .finally(() => {
            this.buttonState = false
          })
      }
    },

    discardChanges () {
      this.objectErrors = Object.assign({}, {})
      this.object = Object.assign(
        {},
        {
          comments: []
        }
      )
      this.comment = Object.assign(
        {},
        {
          id: '',
          comment: ''
        }
      )
      this.formModal = false
    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }
      return array
        .map((currentValue, index, array) => {
          return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
        })
        .join(', ')
    },

    generateGroup () {
      let selectedAlert
      if (this.formType === 'fieldBatch') {
        selectedAlert = this.bagAlerts.find((alert) => alert.id === this.selectedBagAlerts[0])
      } else if (this.formType === 'node') {
        selectedAlert = this.nodeAlerts.find((alert) => alert.id === this.selectedNodeAlerts[0])
      }
      let relatedId
      // Get fieldbatchId from selected alert
      if (this.formType === 'fieldbatch') {
        const fieldBatchId = this.dictBags[selectedAlert.relatedId].fieldBatchId
        relatedId = fieldBatchId
      } else if (this.formType === 'node') {
        relatedId = selectedAlert.relatedId
      }
      const object = {
        relatedType: this.formType,
        relatedId: relatedId,
        eventAt: selectedAlert.eventAt,
        notifications: this.formType === 'fieldbatch' ? this.selectedBagAlerts : this.selectedNodeAlerts
      }

      const variables = {
        object: object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }
      console.log(variables)

      const mutation = generateGroup
      this.$apollo
        .mutate({
          mutation,
          variables
        })
        .then((response) => {
          const { status, errors } = response.data['addNotificationGroup']
          if (status === 'OK') {
            this.objectErrors = {}
            this.selectedBagAlerts = []
            this.formModal = false
            this.$store.dispatch('getMessagesMonitor')
            this.$store.dispatch('getNotificationGroups')
            this.$store.commit('toggleAlert', {
              type: 'success',
              message: this.$i18n.t(
                `notificationsGroup.success.added`
              )
            })
          } else {
            this.objectErrors = errors
            this.$store.commit('toggleAlert', {
              type: 'warning',
              message: this.$i18n.t('errors.invalidFields')
            })
          }
        })
        .catch((error) => {
          this.$store.commit('toggleAlert', {
            type: 'error',
            message: this.$i18n.t('errors.internalError')
          })
        })
        .finally(() => {
          this.buttonState = false
        })
    },

    alertIconColor (status) {
      if (status === 'unatended') {
        return 'yellow darken-2'
      } else if (status === 'atended') {
        return 'green'
      } else if (status === 'finished') {
        return 'white'
      }
    },
    parseDate (date) {
      return moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
    },
    date (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    time (time) {
      return moment(time).format('HH:mm:ss')
    },

    updateMessages () {
      this.$store.dispatch('getMessagesMonitor')
    },

    nodeEventToString (messageEvent, messageTime) {
      if (messageEvent === null) {
        return 'No hay ningun mensaje'
      }
      const seconds = new Date().getTime() / 1000 - messageTime
      if (seconds < 6400) {
        return messageEvent
      } else if (seconds < 7200) {
        return 'Falla Reporte Periódico'
      }

      return 'Falla Reporte Periódico'
    },

    gatewayEventToString (messageEvent, messageTime) {
      if (messageEvent === null) {
        return 'No hay ningun mensaje'
      }
      const seconds = new Date().getTime() / 1000 - messageTime
      if (seconds < 3600 * 3) {
        return messageEvent
      } else if (seconds < 3600 * 6) {
        return 'Falla Reporte Periódico'
      }

      return 'Falla Reporte Periódico'
    },

    chipColorNode (messageTime, statusInt) {
      if (statusInt === 100) {
        return 'red darken-4'
      }
      const seconds = new Date().getTime() / 1000 - messageTime
      if (seconds < 6400) {
        return 'green'
      } else if (seconds < 7400) {
        return 'yellow darken-2'
      }

      return 'red darken-4'
    },

    chipColorGatewaywNode (messageTime, statusInt) {
      if (statusInt === 100) {
        return 'red darken-4'
      }

      const seconds = new Date().getTime() / 1000 - messageTime
      if (seconds < 3600 * 3) {
        if (statusInt === 0) {
          return 'orange darken-1'
        }
        return 'green'
      } else if (seconds < 3600 * 6) {
        return 'yellow darken-2'
      }

      return 'red darken-4'
    },

    colorFromMessage (message) {
      if (message !== undefined) {
        if (message.statusInt === 100) {
          return 'red darken-4'
        }
        return 'green'
      }
      return 'red darken-4'
    },

    messageToText (message) {
      if (message !== undefined) {
        return `${message.status} / ${message.failCounter}`
      }
      return this.$i18n.t('node.noMessages')
    },
    getIconColor (validator) {
      return validator ? 'red' : 'green'
    },
    prepareNotificationBag (notifications) {
      const finalNotfications = []
      const indexNotfications = {}
      for (var i in notifications) {
        const notification = notifications[i]
        if (indexNotfications[notification.name] === undefined) {
          indexNotfications[notification.name] = finalNotfications.length
          finalNotfications.push(notification)
        } else {
          finalNotfications[indexNotfications[notification.name]] = {
            ...finalNotfications[indexNotfications[notification.name]],
            sensorIdentifier: finalNotfications[indexNotfications[notification.name]].sensorIdentifier + ',' + notification.sensorIdentifier

          }
        }
      }
      console.log(finalNotfications)
      return finalNotfications
    }
  }
}
</script>

<style lang="scss">
.bag-card-identifier {
  font-size: 18px;
  font-weight: bold;
}
.bag-card-title {
  font-size: 18px;
}
.messages-grid {
  &__column {
    height: calc(100vh - 102px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(189, 189, 189, 0.1);
      border-radius: 4px;

      &:hover {
        background: rgba(189, 189, 189, 0.2);
      }

      &:active {
        background-color: rgba(189, 189, 189, 0.5);
      }
    }

    &::-webkit-scrollbar-track {
      background: transparent;

      &:hover,
      &:active {
      }
    }
  }
}

.table-bagde {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;

  &.small {
    width: 7px;
    height: 7px;
  }
}

td > div > i {
  font-size: 12px !important;
  margin-right: -5px;
}

.events-card {
  height: 70vh;
  overflow-y: scroll;
  border-radius: 0px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(189, 189, 189, 0.1);
    border-radius: 4px;

    &:hover {
      background: rgba(189, 189, 189, 0.2);
    }

    &:active {
      background-color: rgba(189, 189, 189, 0.5);
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;

    &:hover,
    &:active {
    }
  }

}
.item-disabled {
  pointer-events: none;
}
</style>