z<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <v-flex>
        <v-card
          class="elevation-12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>
            
            <v-spacer />
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="newItem"
                >
                  <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="py-2">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :pagination.sync="pagination"
              next-icon="mdi-chevron-right"
              prev-icon="mdi-chevron-left"
            >
              <template v-slot:body="{ items }">
                <tr v-for="item in items" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td style="text-align: right; padding: 0;">
                    <v-btn
                      flat
                      icon
                      round
                      color="info"
                      @click="showItem(item.id)"
                    >
                      <v-icon>mdi-file-find-outline</v-icon>
                    </v-btn>
                    <v-btn
                      flat
                      icon
                      round
                      color="warning"
                      @click="editItem(item.id)"
                    >
                      <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>
    
                    <v-btn
                      flat
                      icon
                      round
                      color="error"
                      @click="deleteItem(item.id)"
                    >
                      <v-icon>mdi-file-cancel-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card class="elevation-12">
        <!-- <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ title }}</h5></v-card-title> -->
        
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text class="py-2">
          <v-layout row wrap align-center justify-center>
            <v-flex class="px-2" xs12>
              <v-text-field
                v-model="object.name"
                :label="$t(`${objectName.multiple}.name`)"
                prepend-icon="mdi-label"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.name !== undefined"
                :error-messages="parseErrors(objectErrors.name)"
              />
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined" :state="buttonState" color="primary" @click="saveChanges">{{ $t('actions.save') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :state="buttonState" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
          
          <v-btn color="primary" @click="editItem(object.id)">{{ $t('actions.edit', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDelete', { name: $t(`${objectName.multiple}.objectName`) }) }}</v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/products/add.gql'
import edit from '@/graphql/mutations/products/edit.gql'
import object from '@/graphql/queries/products/detail.gql'

const objectName = {
  single: 'product',
  multiple: 'products',
  object: {
    single: 'Product',
    multiple: 'Products'
  }
}

export default {
  components: {
    AButton
  },

  data () {
    return {
      objectName,
      pagination: { rowsPerPage: 10 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      object: {},
      objectErrors: {},
      search: '',
      headers: [
        {
          text: this.$i18n.t(`${objectName.multiple}.name`),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },

  computed: {
    ...mapState(['user', 'width', 'dark', 'language']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor']),

    defaultObject () {
      return {
        id: undefined,
        name: ''
      }
    },

    passwordErrors () {
      if (this.object.id === undefined) {
        return this.parseErrors(this.objectErrors.password)
      } else {
        let errors = this.parseErrors(this.objectErrors.password)
        if (this.object.password.length > 0 && errors.length === 0) {
          return [this.$i18n.t('helpers.passwordWillChange')]
        } else {
          return errors
        }
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.name })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.name })
        }
      }
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
  },

  methods: {
    formatPhone (value) {
      if (value.length === 14) {
        return `+${value[0]}${value[1]}-${value[2]}-${value[3]}${value[4]}${value[5]}-${value[6]}${value[7]}${value[8]}${value[9]}-${value[10]}${value[11]}${value[12]}${value[13]}`
      } else {
        return 'NA'
      }
    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }

      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`edit${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      
      this.isEditting = true
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        let result = response.data[objectName.single]
        
        if (result.status) {
          this.object = result[objectName.single]
          this.formModal = true
          this.isEditting = false
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    discardChanges () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = false
      this.isEditting = false
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
    },

    graphQLWrapper (mutationName) {
      this.buttonState = true
      this.objectErrors = {}
      let variables = {
        object: this.object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename

      let mutation = add

      if (mutationName.includes('edit')) {
        mutation = edit
      }

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        
        let { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          this.object = result
          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.${(mutationName.includes('edit') ? 'updated' : 'created')}`, { name: result.name })
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id) {
      this.deleteId = id
      this.deleteDialog = true
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false
    }
  }
}
</script> 