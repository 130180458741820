<template>
  <v-container fluid class="py-2">
    <v-layout row wrap>
      <v-flex v-if="isCentral" xs12 md6 class="pr-2">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header style="background-color: #04a669">
              <v-row no-gutters justify="center">
                <v-col cols="11">
                  {{ $t("home.nodeAlerts") }}
                </v-col>
                <v-col cols="1" justify="center">
                  <v-avatar
                    color="secondary"
                    size="22"
                    class="float-right ml-1"
                  >
                    <b
                      class="white--text"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ nodeAlerts.length }}
                    </b>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-if="nodeAlerts.length == 0"
                class="d-flex justify-center align-center"
                style="height: 34vh"
              >
                <div class="d-flex flex-column" style="opacity: 0.8">
                  <h4 class="grey--text">No existen datos para mostrar</h4>
                  <v-icon color="grey" size="44">mdi-tea</v-icon>
                </div>
              </div>
              <v-list v-else class="pa-0 events-card">
                <div
                  v-for="item in nodeAlerts"
                  :key="item.id"
                  class="scroller"
                  :items="nodeAlerts"
                  :item-size="32"
                  key-field="id"
                  :emitUpdate="true"
                >
                  <v-divider inset />
                  <v-list-item
                    :three-line="true"
                    style="cursor: pointer"
                    @click="selectNotification(item)"
                  >
                    <v-list-item-avatar>
                      <v-icon
                        :color="alertIconColor(item.notificationStatus)"
                        style="font-size: 1.8rem"
                        >mdi-information</v-icon
                      >
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        >{{ item.notificationName }}
                        {{ parseDate(item.eventAt) }}</v-list-item-title
                      >
                      <v-list-item-subtitle style="font-weight: bold">
                        {{ item.name }} ({{
                          $t(`notificationsStatus.${item.notificationStatus}`)
                        }})</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        class="d-flex justify-start align-center"
                      >
                        <b>{{ item.description }}</b>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>

      <v-flex xs12 :class="isCentral ? 'md6' : 'md12'" class="pl-2">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header style="background-color: #04a669">
              <v-row no-gutters justify="center">
                <v-col cols="11">
                  {{ $t("home.bagAlerts") }}
                </v-col>
                <v-col cols="1" justify="center">
                  <v-avatar
                    color="secondary"
                    size="22"
                    class="float-right ml-1"
                  >
                    <b
                      class="white--text"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ bagAlerts.length }}
                    </b>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-if="bagAlerts.length == 0"
                class="d-flex justify-center align-center"
                style="height: 34vh"
              >
                <div class="d-flex flex-column" style="opacity: 0.8">
                  <h4 class="grey--text">No existen datos para mostrar</h4>
                  <v-icon color="grey" size="44">mdi-tea</v-icon>
                </div>
              </div>
              <v-list v-else class="pa-0 events-card">
                <div
                  v-for="item in bagAlerts"
                  :key="item.id"
                  class="scroller"
                  :items="nodeAlerts"
                  :item-size="32"
                  key-field="id"
                  :emitUpdate="true"
                >
                  <v-divider inset />
                  <v-list-item
                    :three-line="true"
                    style="cursor: pointer"
                    @click="selectNotification(item)"
                  >
                    <v-list-item-avatar>
                      <v-icon
                        :color="alertIconColor(item.notificationStatus)"
                        style="font-size: 1.8rem"
                        >mdi-information</v-icon
                      >
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        >{{ item.notificationName }}
                        {{ parseDate(item.eventAt) }}</v-list-item-title
                      >
                      <v-list-item-subtitle style="font-weight: bold">
                        {{ item.name }} ({{
                          $t(`notificationsStatus.${item.notificationStatus}`)
                        }})</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        class="d-flex justify-start align-center"
                      >
                        <b>{{ item.description }}</b>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>

      <v-flex xs12 class="mt-4">
        <v-tabs v-model="tab" grow dark>
          <v-tab v-if="isCentral" class="white--text">
            {{ $t("home.nodeState") }}
          </v-tab>
          <v-tab class="white--text">
            {{ $t("home.bagState") }}
          </v-tab>
        </v-tabs>
      </v-flex>
      <v-flex v-if="tab === 0 && isCentral" xs12>
        <v-layout row wrap align-center>
          <v-flex xs12 sm7 class="px-4">
            <v-text-field
              v-model="searchNode"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              hide-details
              @keyup="restartPaginator()"
            />
          </v-flex>
          <v-flex xs12 sm5 class="pt-4">
            <v-radio-group v-model="nodeFilterRadio" row>
              <v-radio :label="$t('actions.showAll')" value="showAll" />
              <v-radio :label="$t('actions.showEvents')" value="showEvents" />
            </v-radio-group>
          </v-flex>

          <v-flex
            v-for="item in filteredNodes.slice(
              (nodePage - 1) * itemsPerPage,
              nodePage * itemsPerPage
            )"
            :key="item.id"
            xs12
            sm6
          >
            <v-card :key="item.id" class="grey lighten-2 ma-2">
              <v-card-title class="black--text pa-1 pt-0 pb-0">
                <h6 class="ma-0">{{ item.name }}</h6>
                <v-spacer />
                <v-icon v-if="item.usingBattery" color="black"> mdi-alarm </v-icon>
                <h6 v-if="item.usingBattery" class="ma-0 ml-2">{{ calculateTime(item.usingBatteryDate) }}</h6>
                <v-spacer />
                <span class="primary--text">
                  <b>{{ item.subscriberId }}</b>
                </span>
              </v-card-title>
              <v-divider class="grey lighten-1 mx-1" />
              <v-card-text class="black--text pa-1">
                <v-container>
                  <v-layout row wrap class="py-2 px-2">
                    <v-flex xs12 class="d-flex justify-space-between">
                      <div>
                        <v-icon :color="getIconColor(false)">
                          mdi-satellite-uplink
                        </v-icon>
                        <v-avatar
                          color="grey darken-4"
                          size="30"
                          class="float-right ml-1"
                        >
                          <b class="white--text">
                            {{ item.nodeCounter }}
                          </b>
                        </v-avatar>
                      </div>
                      <div>
                        <v-icon :color="getIconColor(item.loraConnection)">
                          mdi-antenna
                        </v-icon>
                        <v-avatar
                          color="grey darken-4"
                          size="30"
                          class="float-right ml-1"
                        >
                          <b class="white--text">
                            {{ item.e100 }}
                          </b>
                        </v-avatar>
                      </div>
                      <div>
                        <v-icon :color="getIconColor(item.usingBattery)">
                          mdi-flash
                        </v-icon>
                        <v-avatar
                          color="grey darken-4"
                          size="30"
                          class="float-right ml-1"
                        >
                          <b class="white--text">
                            {{ item.e11 }}
                          </b>
                        </v-avatar>
                      </div>
                      <div>
                        <v-icon :color="getIconColor(item.lowBattery)">
                          mdi-battery
                        </v-icon>
                        <v-avatar
                          color="grey darken-4"
                          size="30"
                          class="float-right ml-1"
                        >
                          <b class="white--text">
                            {{ item.e21 }}
                          </b>
                        </v-avatar>
                      </div>
                      <div>
                        <v-icon :color="getIconColor(item.doorOpen)">
                          mdi-door-open
                        </v-icon>
                        <v-avatar
                          color="grey darken-4"
                          size="30"
                          class="float-right ml-1"
                        >
                          <b class="white--text">
                            {{ item.e31 }}
                          </b>
                        </v-avatar>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <div class="text-center mb-6">
              <v-pagination
                v-model="nodePage"
                :length="Math.ceil(filteredNodes.length / itemsPerPage)"
                circle
              />
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="tab === 1 || !isCentral" xs12>
        <v-layout row wrap align-center>
          <v-flex xs12 sm7 class="px-4">
            <v-text-field
              v-model="searchBag"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              hide-details
              @keyup="restartPaginator()"
            />
          </v-flex>
          <v-flex xs12 sm5 class="pt-4">
            <v-radio-group v-model="bagFilterRadio" row>
              <v-radio :label="$t('actions.showAll')" value="showAll" />
              <v-radio :label="$t('actions.showEvents')" value="showEvents" />
            </v-radio-group>
          </v-flex>

          <v-flex
            v-for="item in filteredBags.slice((bagPage - 1) * itemsPerPage, bagPage * itemsPerPage)"
            :key="item.id"
            xs12
            sm6
            class="px-4 pb-2"
          >
            <v-card class="grey lighten-2 pb-2">
              <v-card-title class="black--text pa-1 px-4 pl-6">
                <b class="bag-card-title">{{ item.bagName }}</b>
                <v-spacer />
                <small>
                  <b>Kit: {{ item.kitName }}</b>
                </small>
              </v-card-title>
              <v-divider class="grey lighten-1 mx-1" />
              <v-card-text class="black--text pa-1 pt-0">
                <v-simple-table dense light class="grey lighten-2">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <v-tooltip left color="grey darken-4">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :color="getIconColor()"
                                medium
                                v-bind="attrs"
                                v-on="on"
                                >mdi-run</v-icon
                              >
                            </template>
                            <span class="white--text">{{
                              $t("home.bagEvents.movement")
                            }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.A.eventCounters.e20 }}
                              </b>
                            </v-avatar>
                            {{ "A" }}
                            <template v-if="item.A.lastEvent === 20">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.B.eventCounters.e20 }}
                              </b>
                            </v-avatar>
                            {{ "B" }}
                            <template v-if="item.B.lastEvent === 20">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.C.eventCounters.e20 }}
                              </b>
                            </v-avatar>
                            {{ "C" }}
                            <template v-if="item.C.lastEvent === 20">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.D.eventCounters.e20 }}
                              </b>
                            </v-avatar>
                            {{ "D" }}
                            <template v-if="item.D.lastEvent === 20">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.E.eventCounters.e20 }}
                              </b>
                            </v-avatar>
                            {{ "E" }}
                            <template v-if="item.E.lastEvent === 20">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                      </tr>
                      <tr v-if="isCentral">
                        <td>
                          <v-tooltip left color="grey darken-4">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :color="getIconColor()"
                                medium
                                v-bind="attrs"
                                v-on="on"
                                >mdi-alert-octagon</v-icon
                              >
                            </template>
                            <span class="white--text">{{
                              $t("home.bagEvents.connection")
                            }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.A.eventCounters.e100 }}
                              </b>
                            </v-avatar>
                            {{ "A" }}
                            <template v-if="item.A.lastEvent === 100">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.B.eventCounters.e100 }}
                              </b>
                            </v-avatar>
                            {{ "B" }}
                            <template v-if="item.B.lastEvent === 100">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.C.eventCounters.e100 }}
                              </b>
                            </v-avatar>
                            {{ "C" }}
                            <template v-if="item.C.lastEvent === 100">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.D.eventCounters.e100 }}
                              </b>
                            </v-avatar>
                            {{ "D" }}
                            <template v-if="item.D.lastEvent === 100">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.E.eventCounters.e100 }}
                              </b>
                            </v-avatar>
                            {{ "E" }}
                            <template v-if="item.E.lastEvent === 100">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                      </tr>
                      <tr v-if="isCentral">
                        <td>
                          <v-tooltip left color="grey darken-4">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :color="getIconColor()"
                                medium
                                v-bind="attrs"
                                v-on="on"
                                >mdi-battery-10</v-icon
                              >
                            </template>
                            <span class="white--text">{{
                              $t("home.bagEvents.battery")
                            }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.A.eventCounters.e10 }}
                              </b>
                            </v-avatar>
                            {{ "A" }}
                            <template v-if="item.A.lastEvent === 10">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.B.eventCounters.e10 }}
                              </b>
                            </v-avatar>
                            {{ "B" }}
                            <template v-if="item.B.lastEvent === 10">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.C.eventCounters.e10 }}
                              </b>
                            </v-avatar>
                            {{ "C" }}
                            <template v-if="item.C.lastEvent === 10">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.D.eventCounters.e10 }}
                              </b>
                            </v-avatar>
                            {{ "D" }}
                            <template v-if="item.D.lastEvent === 10">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-center justify-end bag-card-identifier"
                          >
                            <v-avatar
                              color="grey darken-4"
                              size="22"
                              class="float-right mr-1"
                            >
                              <b class="white--text">
                                {{ item.E.eventCounters.e10 }}
                              </b>
                            </v-avatar>
                            {{ "E" }}
                            <template v-if="item.E.lastEvent === 10">
                              <v-icon color="amber" class="ml-1"
                                >mdi-alert</v-icon
                              >
                            </template>
                            <div v-else class="table-bagde small ml-1" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <div class="text-center mb-6">
              <v-pagination
                v-model="bagPage"
                :length="Math.ceil(filteredBags.length / itemsPerPage)"
                circle
              />
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap pa-1 align-center justify-center>
            <v-flex xs12 sm6 align="left">
              <h5>{{ $t("notifications.notificationType") }}</h5>
              <b> {{ object.notificationName }} </b>
            </v-flex>
            <v-flex xs12 sm6 align="left">
              <h5>{{ $t("notifications.eventAt") }}</h5>
              <b> {{ parseDate(object.eventAt) }} </b>
            </v-flex>
            <v-flex xs12 sm6 align="left">
              <h5>{{ $t("notifications.name") }}</h5>
              <b> {{ object.name }} </b>
            </v-flex>
            <v-flex xs12 sm6 align="left">
              <h5>{{ $t("notifications.notificationStatus") }}</h5>
              <b>
                {{ $t(`notificationsStatus.${object.notificationStatus}`) }}
              </b>
            </v-flex>
            <v-flex xs12 align="left">
              <h5>{{ $t("notifications.description") }}</h5>
              <b> {{ object.description }} </b>
            </v-flex>
            <v-flex xs12>
              <h5>{{ $t("notifications.comments") }}</h5>
              <b v-if="object.comments.length == 0">
                {{ $t(`notifications.NoComments`) }}
              </b>
              <v-timeline v-else :dense="true">
                <v-timeline-item
                  v-for="comment in object.comments"
                  :key="comment.timestamp"
                  color="primary"
                  small
                >
                  <v-card class="elevation-2">
                    <v-card-title class="headline"
                      >{{ comment.username }}
                      {{ parseDate(comment.timestamp) }}</v-card-title
                    >
                    <v-card-text>
                      {{ comment.comment }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-flex>
            <v-flex
              v-if="object.notificationStatus !== 'finished'"
              xs12
              class="pa-4"
            >
              <v-textarea
                v-model="comment.comment"
                :label="$t(`notifications.comment`)"
                :color="inputColor"
                :error="objectErrors.comment !== undefined"
                :error-messages="parseErrors(objectErrors.comment)"
              />
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions style="max-height: 70px">
          <v-btn color="error" @click="discardChanges">{{
            $t("actions.close")
          }}</v-btn>

          <v-spacer />

          <a-button
            v-if="
              object.notificationStatus !== 'finished' &&
              user.parentType === 'Company::Central'
            "
            color="primary"
            :state="buttonState"
            @click="executeMutation('close')"
            >{{ $t("actions.closeNotification") }}</a-button
          >

          <v-spacer />

          <a-button
            v-if="object.notificationStatus !== 'finished'"
            color="primary"
            :state="buttonState"
            @click="executeMutation('add')"
            >{{ $t("actions.addComment") }}</a-button
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import { RecycleScroller } from "vue-virtual-scroller";
import AButton from "@/components/Button.vue";

import addComment from "@/graphql/mutations/notifications/addComment.gql";
import finish from "@/graphql/mutations/notifications/finish.gql";
import object from "@/graphql/queries/notifications/detail.gql";

const objectName = {
  single: "message",
  multiple: "messages",
  object: {
    single: "Message",
    multiple: "Messages",
  },
};
export default {
  components: {
    AButton,
    RecycleScroller,
  },
  data() {
    return {
      itemsPerPage: 10,
      nodePage: 1,
      nodeFilterRadio: "showAll",
      searchNode: "",
      bagPage: 1,
      bagFilterRadio: "showAll",
      searchBag: "",
      tab: 0,
      objectName,
      object: {
        comments: [],
      },
      comment: {
        id: "",
        comment: "",
      },
      objectErrors: {},
      formModal: false,
      buttonState: false,
      curTime: new Date(),
    };
  },
  computed: {
    ...mapState([
      "messagesMonitor",
      "loraNodeMessages",
      "loraSensorsMessages",
      "btSensorsMessages",
      "messagesReport",
      "user",
      "width",
      "dark",
      "language",
      "alerts",
      "bagsMonitorMessages",
    ]),
    ...mapGetters(["inputColor", "nodeAlerts", "bagAlerts"]),

    isCentral () {
      return this.user.parentType === "Company::Central"
    },
    title() {
      return `${this.object.notificationName} (${this.object.name})`;
    },
    bagsMonitorMessagesArray() {
      const array = [];
      for (var i in this.bagsMonitorMessages) {
        array.push(this.bagsMonitorMessages[i]);
      }

      return array;
    },
    isFullScreen() {
      return this.width.full < 990;
    },

    messagesParsed() {
      const messages = [];
      const monitor = Object.entries(this.messagesMonitor);
      for (var i = monitor.length - 1; i >= 0; i--) {
        messages.push(monitor[i][1]);
      }
      return messages;
    },

    reportMessagesParsed() {
      const messages = [];
      const monitor = Object.entries(this.messagesReport);
      for (var i = monitor.length - 1; i >= 0; i--) {
        messages.push(monitor[i][1]);
      }
      return messages;
    },

    filteredNodes() {
      let items = [];
      if (this.nodeFilterRadio === "showAll") {
        items = this.loraNodeMessages;
      } else {
        items = this.loraNodeMessages.filter((item) =>
          this.nodeAlerts.find((alert) => alert.relatedId === item.id)
        );
      }
      if (this.searchNode === "") {
        return items;
      } else {
        return items.filter((item) =>
          this.validateSearch(
            `${item.name}${item.subscriberId}`,
            this.searchNode
          )
        );
      }
    },

    filteredBags() {
      let items = [];

      if (this.bagFilterRadio === "showAll") {
        items = this.bagsMonitorMessagesArray;
      } else {
        items = this.bagsMonitorMessagesArray.filter((item) =>
          this.nodeAlerts.find((alert) => alert.relatedId === item.id)
        );
      }
      if (this.searchBag === "") {
        return items;
      } else {
        return items.filter((item) =>
          this.validateSearch(`${item.bagName}${item.kitName}`, this.searchBag)
        );
      }
    },
  },
  created() {
    // const id = setInterval(this.updateMessages, 60000)
    // this.$store.commit('setIntervalId', id)
    setInterval(()=> this.curTime = new Date(), 1000)
  },

  methods: {
    appendZero (value) {
      if (value < 10) {
        return `0${value}`
      }
      return value
    },
    parseTime (time) {
      const hours = this.appendZero(Math.floor(time / 3600));
      time = time - hours * 3600;
      const minutes = this.appendZero(Math.floor(time / 60));
      const seconds = this.appendZero(Math.floor(time - minutes * 60));
      return `${hours}:${minutes}:${seconds}`


    },
    calculateTime (time) {
      const finalTime = (this.curTime/ 1000) - time
      return this.parseTime(finalTime)

    },
    restartPaginator() {
      console.log("Hello");
      this.nodePage = 1;
      this.bagPage = 1;
    },
    validateSearch(string, search) {
      return string.toLowerCase().includes(search.toLowerCase());
    },
    executeMutation(action) {
      let mutation;
      let mutationName;

      if (this.comment.comment.length < 4) {
        this.objectErrors.comment = [
          "El comentario no puede tener menos de 4 caracters",
        ];
        this.$forceUpdate();
      } else {
        if (action === "add") {
          mutationName = "addCommentNotification";
          mutation = addComment;
        } else if (action === "close") {
          mutationName = "finishNotification";
          mutation = finish;
        }

        const variables = {
          object: this.comment,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase(),
        };
        this.buttonState = true;
        this.$apollo
          .mutate({
            mutation,
            variables,
          })
          .then((response) => {
            const { status, errors, result } = response.data[mutationName];
            if (status === "OK") {
              this.object = {
                ...result,
              };
              this.comment = {
                id: this.object.id,
                comment: "",
              };

              this.objectErrors = {};
              this.$store.dispatch("getMessagesMonitor");
              this.$store.commit("toggleAlert", {
                type: "success",
                message: this.$i18n.t(
                  `notifications.success.${
                    mutationName.includes("close") ? "closed" : "added"
                  }`
                ),
              });
            } else {
              this.objectErrors = errors;
              this.$store.commit("toggleAlert", {
                type: "warning",
                message: this.$i18n.t("errors.invalidFields"),
              });
            }
          })
          .catch((error) => {
            this.$store.commit("toggleAlert", {
              type: "error",
              message: this.$i18n.t("errors.internalError"),
            });
          })
          .finally(() => {
            this.buttonState = false;
          });
      }
    },
    alertIconColor(status) {
      if (status === "unatended") {
        return "yellow darken-2";
      } else if (status === "atended") {
        return "green";
      } else if (status === "finished") {
        return "white";
      }
    },
    parseDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    date(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    time(time) {
      return moment(time).format("HH:mm:ss");
    },

    updateMessages() {
      this.$store.dispatch("getMessagesMonitor");
    },

    nodeEventToString(messageEvent, messageTime) {
      if (messageEvent === null) {
        return "No hay ningun mensaje";
      }
      const seconds = new Date().getTime() / 1000 - messageTime;
      if (seconds < 6400) {
        return messageEvent;
      } else if (seconds < 7200) {
        return "Falla Reporte Periódico";
      }

      return "Falla Reporte Periódico";
    },

    gatewayEventToString(messageEvent, messageTime) {
      if (messageEvent === null) {
        return "No hay ningun mensaje";
      }
      const seconds = new Date().getTime() / 1000 - messageTime;
      if (seconds < 3600 * 3) {
        return messageEvent;
      } else if (seconds < 3600 * 6) {
        return "Falla Reporte Periódico";
      }

      return "Falla Reporte Periódico";
    },

    chipColorNode(messageTime, statusInt) {
      if (statusInt === 100) {
        return "red darken-4";
      }
      const seconds = new Date().getTime() / 1000 - messageTime;
      if (seconds < 6400) {
        return "green";
      } else if (seconds < 7400) {
        return "yellow darken-2";
      }

      return "red darken-4";
    },

    chipColorGatewaywNode(messageTime, statusInt) {
      if (statusInt === 100) {
        return "red darken-4";
      }

      const seconds = new Date().getTime() / 1000 - messageTime;
      if (seconds < 3600 * 3) {
        if (statusInt === 0) {
          return "orange darken-1";
        }
        return "green";
      } else if (seconds < 3600 * 6) {
        return "yellow darken-2";
      }

      return "red darken-4";
    },

    colorFromMessage(message) {
      if (message !== undefined) {
        if (message.statusInt === 100) {
          return "red darken-4";
        }
        return "green";
      }
      return "red darken-4";
    },

    messageToText(message) {
      if (message !== undefined) {
        return `${message.status} / ${message.failCounter}`;
      }
      return this.$i18n.t("node.noMessages");
    },

    async selectNotification(notification) {
      const id = notification.id
      await this.$apollo
        .query({
          query: object,
          variables: {
            id,
            apiToken: this.user.apiToken,
            language: this.language.toUpperCase()
          },
          fetchPolicy: 'no-cache'
        })
        .then((response) => {
          const result = response.data.notification

          if (result.status) {
            this.object = {
              ...result.notification
            }

            this.comment.id = this.object.id

            this.formModal = true
            this.isEditting = false
            setTimeout(this.initMapShow, 1000)
            setTimeout(this.drawNode, 1000)
            setTimeout(this.drawBags, 1000)
          } else {
            console.log('error')
          }
        })
        .catch((error) => {
          this.$store.commit('toggleAlert', {
            type: 'error',
            message: this.$i18n.t('errors.internalError')
          })
        })
    },

    discardChanges () {
      this.objectErrors = Object.assign({}, {})
      this.object = Object.assign(
        {},
        {
          comments: []
        }
      )
      this.comment = Object.assign(
        {},
        {
          id: '',
          comment: ''
        }
      )
      this.formModal = false
    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }
      return array
        .map((currentValue, index, array) => {
          return currentValue.charAt(0).toUpperCase() + currentValue.substr(1);
        })
        .join(', ')
    },

    getIconColor(validator) {
      return validator ? "red" : "green";
    },
  },
};
</script>

<style lang="scss">
.bag-card-identifier {
  font-size: 18px;
  font-weight: bold;
}
.bag-card-title {
  font-size: 18px;
}
.messages-grid {
  &__column {
    height: calc(100vh - 102px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(189, 189, 189, 0.1);
      border-radius: 4px;

      &:hover {
        background: rgba(189, 189, 189, 0.2);
      }

      &:active {
        background-color: rgba(189, 189, 189, 0.5);
      }
    }

    &::-webkit-scrollbar-track {
      background: transparent;

      &:hover,
      &:active {
      }
    }
  }
}

.table-bagde {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;

  &.small {
    width: 7px;
    height: 7px;
  }
}

td > div > i {
  font-size: 12px !important;
  margin-right: -5px;
}

.events-card {
  height: 34vh;
  overflow-y: scroll;
  border-radius: 0px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(189, 189, 189, 0.1);
    border-radius: 4px;

    &:hover {
      background: rgba(189, 189, 189, 0.2);
    }

    &:active {
      background-color: rgba(189, 189, 189, 0.5);
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;

    &:hover,
    &:active {
    }
  }
}
</style>