import Layout from '@/layouts/platform/Panel.vue'
import Home from '@/pages/platform/Home.vue'
import Map from '@/pages/platform/Map.vue'
// Hardware elements
import LoraNodes from '@/pages/platform/lora/Nodes.vue'
import LoraSensors from '@/pages/platform/lora/Sensors.vue'
import BtSensors from '@/pages/platform/BtSensors.vue'

// Field Batch
import FieldBatches from '@/pages/platform/FieldBatches.vue'

// Bags
import Bags from '@/pages/platform/Bags.vue'
import HistoricBags from '@/pages/platform/HistoricBags.vue'
// Bindings
import Bind from '@/pages/platform/Bind.vue'
import Unbind from '@/pages/platform/Unbind.vue'

// Orders
import Orders from '@/pages/platform/Orders.vue'
import OrderApproval from '@/pages/platform/OrderApproval.vue'
import OrdersReports from '@/pages/platform/OrdersReports.vue'
// Messages
import Messages from '@/pages/platform/Messages.vue'
import Reports from '@/pages/platform/Reports.vue'
// Incidents
import Incidents from '@/pages/platform/Incidentsv2.vue'

// User Information
import UserInformation from '@/pages/platform/UserInformation.vue'

// Kits
import Kits from '@/pages/platform/Kits.vue'
import LoadKits from '@/pages/platform/LoadKits.vue'

// Notifications
import Notifications from '@/pages/platform/Notifications.vue'

export default [
  {
    path: '/Platform',
    component: Layout,
    children: [
      {
        path: 'Home',
        name: 'platformHome',
        components: { default: Home }
      },
      {
        path: 'Map',
        name: 'platformMap',
        components: { default: Map }
      },
      {
        path: 'Lora/Nodes',
        name: 'platformLoraNodes',
        components: { default: LoraNodes }
      },
      {
        path: 'Lora/Sensors',
        name: 'platformLoraSensors',
        components: { default: LoraSensors }
      },
      {
        path: 'FieldBatches',
        name: 'platformFieldBatches',
        components: { default: FieldBatches }
      },
      {
        path: 'Bt/Sensors',
        name: 'platformBtSensors',
        components: { default: BtSensors }
      },
      {
        path: 'Bags',
        name: 'platformBags',
        components: { default: Bags }
      },
      {
        path: 'HistoricBags',
        name: 'platformHistoricBags',
        components: { default: HistoricBags }
      },
      {
        path: 'Sensors/Bind',
        name: 'platformBind',
        components: { default: Bind }
      },
      {
        path: 'Sensors/Unbind',
        name: 'platformUnbind',
        components: { default: Unbind }
      },
      {
        path: 'Orders',
        name: 'platformOrders',
        components: { default: Orders }
      },
      {
        path: 'OrdersReports',
        name: 'platformOrdersReports',
        components: { default: OrdersReports }
      },
      {
        path: 'OrderApproval',
        name: 'platformOrderApproval',
        components: { default: OrderApproval }
      },
      {
        path: 'Messages',
        name: 'platformMessages',
        components: { default: Messages }
      },
      {
        path: 'Reports',
        name: 'platformReports',
        components: { default: Reports }
      },
      {
        path: 'Incidents',
        name: 'platformIncidents',
        components: { default: Incidents }
      },
      {
        path: 'UserInformation',
        name: 'platformUserInformation',
        components: { default: UserInformation }
      },
      {
        path: 'Kits',
        name: 'platformKits',
        components: { default: Kits }
      },
      {
        path: 'LoadKits',
        name: 'platformLoadKits',
        components: { default: LoadKits }
      },
      {
        path: 'Notifications',
        name: 'platformNotifications',
        components: { default: Notifications }
      }
    ]
  }
]
