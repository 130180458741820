<template>
  <v-app-bar flat color="transparent">
    <v-app-bar-nav-icon
      v-if="width.full < 960"
      @click.stop="setSidebar(true)"
    ></v-app-bar-nav-icon>
    <v-toolbar-title><h4 class="pa-0 ma-0" :class="dark ? 'white--text' : ''">{{ $t(`pages.${$route.name}`) }}</h4>
    <small style="font-size: 14px; opacity: 0.7" :class="dark ? 'white--text' : '' ">{{ localVersion }}</small></v-toolbar-title>

    <v-spacer />
  
    <v-menu offset-y>
      <template v-slot:activator="{ on: menu }">
        <v-tooltip left>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...menu }" icon small>
              <v-icon>mdi-progress-wrench</v-icon>
            </v-btn>
          </template>

          <span class="white--text">{{ $t('app.tools.title') }}</span>
        </v-tooltip>
      </template>
      
      <v-list>
        <v-list-item link>
          <v-list-item-title @click="setDark(!dark)">{{ $t(`app.tools.darkMode.${dark}`) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: mapState(['sidebar', 'width', 'dark', 'localVersion']),

  methods: {
    ...mapMutations(['setDark', 'setSidebar'])
  }
}
</script>

<style lang="scss">
  .v-toolbar:not(.v-toolbar--fixed) .v-toolbar__content{
    margin-left: 0;
  }
</style>