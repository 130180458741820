<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="messagesSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex>
        <v-card
          id="messagesCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>

            <v-spacer />

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="updateMessages"
                >
                  <v-icon>mdi-package-down</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              id="messagesSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :options.sync="pagination"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tr v-for="item in items" :key="item.id">
                  <td class="text-xs-right">
                    {{ item.message_id }}
                  </td>
                  <td class="text-xs-right">{{ date(item.message_time) }} <br> {{ time(item.message_time) }}</td>
                  <td class="text-xs-right">{{ item.sensor }}</td>
                  <td>{{ item.my_event_name }}</td>
                  <td>{{ item.bag_name }}</td>
                  <td>{{ item.field_batch }}</td>
                  <td>{{ item.lora_node }}</td>
                  <td>{{ item.lora_sensor }}</td>
                  <td>{{ item.bt_sensor }}</td>
                  <td class="text-xs-right">{{ item.temps }}</td>
                  <td class="text-xs-right">{{ item.vbats }}</td>
                  <td class="text-xs-right">{{ item.vbatg }}</td>
                  <td>{{ item.message_virtual ? 'si' : 'no' }}</td>
                </tr>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'
import DualList from 'vuetify-dual-list'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/bags/add.gql'
import edit from '@/graphql/mutations/bags/edit.gql'
import object from '@/graphql/queries/bags/detail.gql'
import moment from 'moment'
const objectName = {
  single: 'bagmessage',
  multiple: 'messages',
  object: {
    single: 'Message',
    multiple: 'Messages'
  }
}

export default {
  components: {
    AButton,
    DualList
  },

  data () {
    return {
      objectName,
      keys: { primary: 'name', secondary: 'deviceEui' },
      i18n: this.$i18n.t('duallist3'),
      dense: true,
      drawingManager: null,
      pagination: { rowsPerPage: 10 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      map: null,
      geofence: null,
      drawer: null,
      object: {},
      objectErrors: {},
      search: '',
      headers: [
        { 
          align: 'left',
          sortable: true,
          value: 'message_id',
          text: this.$i18n.t(`${objectName.multiple}.id`) 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.messageTime`),
          value: 'message_time'
        },
        
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.sensorNumber`),
          value: 'sensor' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.event`),
          value: 'my_event_name' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.bagName`),
          value: 'bag_name' 
        },
        
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.fieldBatch`),
          value: 'field_batch' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.loraNode`),
          value: 'lora_node' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.loraSensors`),
          value: 'lora_sensor' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.sensors`),
          value: 'bt_sensor' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.temperature`),
          value: 'temps' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.voltage`),
          value: 'vbats'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.nodeVoltage`),
          value: 'vbatsg'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.virtualSensor`),
          value: 'virtual_sensor'
        }

      ]
    }
  },

  computed: {
    ...mapState(['user', 'width', 'dark', 'language', 'messages']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'unlinkedLoraSensors']),

    defaultObject () {
      return {
        id: undefined,
        reference: '',
        nomenclature: '',
        cycleId: null,
        productId: null,
        fieldBatchId: null,
        geofence: [],
        estimatedWeight: 0,
        realWeight: 0,
        localContactName: '',
        localContactPhone: '',
        loraSensors: [],
        newShape: null
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.reference })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.reference })
        }
      }
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#messagesCard')
    const search = document.getElementById('messagesSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    handleScroll (event) {
      const elem = document.getElementById('messagesCard')
      const search = document.getElementById('messagesSearchFixed').childNodes[0]
      const searchCard = document.getElementById('messagesSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },

    date (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    time (time) {
      return moment(time).format('HH:mm:ss')
    },
    
    updateMessages () {
      this.$store.dispatch('getMessages')
    }  
  }
}
</script> 