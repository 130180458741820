export default {
  setServerVersion (state, payload) {
    state.serverVersion = payload
  },

  setButtonState (state, payload) {
    state.buttonState = payload
  },

  setDark (state, payload) {
    state.dark = payload
  },

  setLoading (state, payload) {
    state.isLoading = payload
  },

  setMini (state, payload) {
    state.mini = payload
  },

  setSidebar (state, payload) {
    state.sidebar = payload
  },

  setUser (state, payload) {
    state.user = payload
  },

  updateMaxWidth (state, payload) {
    if (payload <= 960) {
      state.width = {
        full: payload,
        dialog: payload
      }
    } else {
      state.width = {
        full: payload,
        dialog: (payload / 8 * 7)
      }
    }
  },

  toggleAlert (state, payload) {},

  setBags (state, payload) {
    state.bags = payload
    const dictList = {}
    for (let i = 0; i < payload.length; i++) {
      dictList[payload[i].id] = payload[i]
    }
    state.dictBags = dictList
  },

  setBag (state, payload) {
    state.bag = payload
  },

  setBtSensors (state, payload) {
    state.btSensors = payload
  },

  setLoraSensors (state, payload) {
    state.loraSensors = payload
  },

  setLoraNodes (state, payload) {
    state.loraNodes = payload
  },

  setUsers (state, payload) {
    state.users = payload
  },

  setInstallers (state, payload) {
    state.installers = payload
  },

  setCompanyCentrals (state, payload) {
    state.companyCentrals = payload
  },

  setCompanyPromotors (state, payload) {
    state.companyPromotors = payload
  },

  setCompanyLocals (state, payload) {
    state.companyLocals = payload
  },

  setCompanyDepositories (state, payload) {
    state.companyDepositories = payload
  },

  setCategoryIncidents (state, payload) {
    state.categoryIncidents = payload
  },

  setCategoryAnalyses (state, payload) {
    state.categoryAnalyses = payload
  },

  setProducts (state, payload) {
    state.products = payload
  },

  setCycles (state, payload) {
    state.cycles = payload
  }, 

  setFieldBatches (state, payload) {
    state.fieldBatches = payload
  },

  updateToken (state, payload) {
    state.user.apiToken = payload
  },

  toggleSidebar (state, payload = undefined) {
    state.sidebarStatus = (payload === undefined ? !state.sidebarStatus : payload)
  },

  setOrders (state, payload) {
    state.orders = payload
  },

  setCategoryOrders (state, payload) {
    state.categoryOrders = payload
  },

  setMessages (state, payload) {
    state.messages = payload
  },

  setMessagesReport (state, payload) {
    const messages = {}
    for (var i = payload.length - 1; i >= 0; i--) {
      messages[payload[i].bag_id] = payload[i]
    }
    state.messagesReport = messages
  },
  setMessagesMonitor (state, payload) {
    state.loraNodeMessages = payload[0]
    const loraSensors = payload[1]
    const bags = {}
    for (var i in loraSensors) {
      if (bags[loraSensors[i].bagId] === undefined) {
        bags[loraSensors[i].bagId] = {
          id: loraSensors[i].bagId,
          bagName: loraSensors[i].bagName,
          fieldBatch: loraSensors[i].fieldBatchName,
          node: loraSensors[i].loraNodeName,
          kitName: loraSensors[i].kitName,
          A: {},
          B: {},
          C: {},
          D: {},
          E: {}
        }
      }
      bags[loraSensors[i].bagId][loraSensors[i].ident] = loraSensors[i]
      bags[loraSensors[i].bagId][loraSensors[i].ident].eventCounters = JSON.parse(bags[loraSensors[i].bagId][loraSensors[i].ident].eventCounters)
    }

    const btSensors = payload[2]

    // eslint-disable-next-line no-redeclare
    for (var i in btSensors) {
      if (bags[btSensors[i].bagId] === undefined) {
        bags[btSensors[i].bagId] = {
          id: btSensors[i].bagId,
          bagName: btSensors[i].bagName,
          fieldBatch: btSensors[i].fieldBatchName,
          node: btSensors[i].loraNodeName,
          kitName: btSensors[i].kitName,
          A: {},
          B: {},
          C: {},
          D: {},
          E: {}
        }
      }
      bags[btSensors[i].bagId][btSensors[i].ident] = btSensors[i]
    }
    state.bagsMonitorMessages = bags
  },
  setCategoryAlerts (state, payload) {
    state.categoryAlerts = payload
  },
  
  setPresetRuleNotifications (state, payload) {
    state.presetRuleNotifications = payload
  },

  setIncidents (state, payload) {
    state.incidents = payload
  },
  
  setPriorities (state, payload) {
    state.priorities = payload
  },

  setKits (state, payload) {
    state.kits = payload
  },

  setUsersSelect (state, payload) {
    state.usersSelect = payload
  },

  setAlerts (state, payload) {
    // const alerts = payload
    // for (let i = 0; i < alerts.length; i++) {
    //   let object
    //   if (alerts[i].notification_type === 'bag' ) {
    //     object = state.bags.find((bag) => bag.id === alerts[i].related_id)
    //   } else if (alerts[i].notification_type === 'node') {
    //     object = state.loraNodes.find((loraNode) => loraNode.id === alerts[i].related_id)
    //   } else {
    //     continue
    //   }
    //   alerts[i].object = object
    // }
    state.alerts = payload
  },

  updateMessagesMonitor (state, payload) {
    
  },

  setIntervalId (state, payload) {
    if (state.setIntervalId !== undefined) {
      clearInterval(state.IntervalId)
    }
    state.IntervalId = payload
  },

  setNotificationGroups (state, payload) {
    state.notificationGroups = payload
  },

  setBagNotificationGroups (state, payload) {
    console.log('setBagNotificationGroups', payload)
    state.bagNotificationGroups = payload
  },
  setNodeNotificationGroups (state, payload) {
    console.log('setNodeNotificationGroups', payload)
    state.nodeNotificationGroups = payload
  }
}
