export default {
  serverVersion: 'v1.0.2-r1',
  localVersion: 'v1.0.2-r1',
  intervalId: undefined,
  buttonState: false,
  dark: true,
  isLoading: true,
  language: 'es',
  sidebar: true,
  mini: false,
  user: {
    id: 0,
    name: 'Test'
  },
  width: {
    dialog: (window.innerWidth / 8 * 7),
    full: window.innerWidth
  },
  categoryIncidents: [],
  categoryAnalyses: [],
  products: [],
  analyses: [],
  fieldBatches: [],
  users: [],
  usersSelect: [],
  installers: [],
  companyCentrals: [],
  companyPromotors: [],
  companyLocals: [],
  companyDepositories: [],
  loraNodes: [],
  loraSensors: [],
  btSensors: [],
  cycles: [],
  bags: [],
  dictBags: {},
  kits: [],
  bag: {
    loraSensors: []
  },
  sidebarStatus: true,
  isLocal: process.env.NODE_ENV === 'development',
  categoryOrders: [],
  orders: [],
  messages: [],
  alerts: [],
  messagesMonitor: {},
  messagesReport: {},
  categoryAlerts: [],
  presetRuleNotifications: [],
  incidents: [],
  priorities: [],
  loraNodeMessages: [],
  loraSensorsMessages: [],
  btSensorsMessages: [],
  bagsMonitorMessages: {},
  nodeNotificationGroups: [],
  bagsNotificationGroups: []
}
